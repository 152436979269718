import companyDocumentsData from "@/constants/lg-en/companyDocuments";
import ConfigureDocumentsHelper from "@/mixins/ConfigureDocumentHelper";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import EntityHelper from "@/mixins/EntitiesHelper";
import { getAllCompanyTemplatesByPagination } from "@/repo/templatesRepo";
import axios from "@/config/axios";
import { makePlainText } from "@/helpers/appHelper.js";
import { postAPICall } from "../helpers/httpHelper";
import moment from "moment";
import { mapGetters } from "vuex";
import { bus } from "../main";
var pdfjsLib = window["pdfjs-dist/build/pdf"];
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.worker.js";
//import moment from 'moment';
import * as mexp from "math-expression-evaluator";
export default {
  data() {
    return {
      templatesDataInCk: [],
      mentionData: [],
      isEmptyValueInTableRow: false,
      getAllFormTemplatesData: [],
      getAllEntities: null,
      multipleSelectionOfAiExtraFields: [],
      unmatchedFieldLabels: [],
      loading: false,
      loadingText: "",
      filteredMapFilledByFieldLabels: [],
      searchedMapLables: [],
      previewEditorContent: "",
      fetchingCompanyDocumentDetails: false,
      configureCompanyDocumentsVisible: false,
      newlySelectedDocuments: {
        static: [],
        standard: [],
        mappedFieldsOfUsers: [],
        checkSignerUsers: [],
        custom: [],
        requested: [],
      },
      searchDocumentFormData: {
        type: "",
        group_id: "",
        category_id: "",
        searchedDocuments: [],
      },
      standardDocumentsMap: companyDocumentsData.STANDARD_DOCUMENTS_MAP,
      standardDocumentsList: companyDocumentsData.STANDARD_DOCUMENTS_LIST,

      documentViewComponentsMap:
        companyDocumentsData.EMP_DOC_VIEW_COMPONENT_MAP,
    };
  },
  mixins: [ConfigureDocumentsHelper, FormbuilderHelper, EntityHelper],
  computed: {
    ...mapGetters("companyDocuments", ["getCompanyAllDocuments"]),
    ...mapGetters("templatesData", ["getTemplateDataByentityId"]),
    ...mapGetters("documentCategories", ["getAllCategories"]),
    ...mapGetters("documentGroups", ["getAllGroups"]),
    ...mapGetters("contactTypes", ["getAllContactTypes"]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("auth", ["getActiveWorkspace"]),
    // ...mapGetters("companyTemplates", ["getAllFormTemplatesData"]),
    ...mapGetters("entities", [
      // "getAllEntities",
      "getEntityDataByEntityId",
      "getEntityRecordsForTable",
    ]),
    getTotalDocuments() {
      return (documents) => {
        return (
          documents.static.length +
          documents.custom.length +
          documents.standard.length +
          documents.requested.length
        );
      };
    },
    checkForChildren() {
      return (this.elements || []).filter(
        (e) => e.parent_entity_field_key == this.selectedItem.key
      ).length
        ? true
        : false;
    },
    getSignerUserTypeLength() {
      let data = (this.documentRecipientsList || this.documentUsers).filter((el) => {
        if ((el && el.user_type == "SIGNER") || el.user_type == "APPROVER") {
          return this.signerUserType.push(el.user_type);
        }
      });
      return data.length;
      //signerUserType
    },
    checkForChildrenWithActiveParent() {
      return (this.elements || []).filter(
        (e) =>
          this.elements[this.activatedItemIndex] &&
          e.parent_entity_field_key ==
            this.elements[this.activatedItemIndex].key
      ).length
        ? true
        : false;
    },
    checkForRepeatableChild() {
      return (this.elements || []).filter(
        (e) =>
          this.elements[this.activatedItemIndex] &&
          e.parent_repeatable_field_key ==
            this.elements[this.activatedItemIndex].key
      ).length
        ? true
        : false;
    },
    checkForRepeatableChildren() {
      return (this.elements || []).filter(
        (e) => e.parent_repeatable_field_key == this.selectedItem.key
      ).length
        ? true
        : false;
    },
    //standard contact type
    getStandardContactType() {
      if (this.getAllContactTypes?.length) {
        return this.getAllContactTypes.find(
          (e) => e.type == "STANDARD" && e.slug == "receiver"
        );
      }
      return "";
    },
  },
  methods: {
    async fetchAllFormTemplates() {
      try {
        this.getAllFormTemplatesData = await getAllCompanyTemplatesByPagination(
          {
            get_all: true,

            include_standard: true,
          }
        );
      } catch (err) {
        this.$message.error("Error", err);
      }
    },
    handleMultiSelectChange(item) {
      if (
        item &&
        item.options &&
        item.options.length &&
        item.max_selection !== 0 &&
        item.value.length > item.max_selection
      ) {
        item.value.pop();
        this.$message.error(
          `You can select up to ${item.max_selection} options`
        );
      }
    },
    closeAiAddedEditorFields() {
      this.aIgeneratedExtraFields = false;
      this.editorContent = this.editorContent
        .replace(/\[\[.*?\]\]/g, "")
        .trim();
    },
    currencyVariable(item) {
      if (item.validations.currency == "INR") {
        item.validations.locale = "en-IN";
      } else if (item.validations.currency == "USD") {
        item.validations.locale = "en-US";
      } else if (item.validations.currency == "MXN") {
        item.validations.locale = "es-MX";
      } else if (item.validations.currency == "ZAR") {
        item.validations.locale = "en-ZA";
      } else if (item.validations.currency == "MYR") {
        item.validations.locale = "ms-MY";
      } else if (item.validations.currency == "GBP") {
        item.validations.locale = "en-GB";
      } else {
        item.validations.locale = "en-IE";
      }
    },
    handleLabelChangeSenderSide(index) {
      const currentLabelObj = this.editorFields[index];
      const newLabel = currentLabelObj.label.trim();
      const oldLabel = currentLabelObj.fixedLabel.trim();

      // If the new label is the same as the old label, do nothing
      if (newLabel === oldLabel) {
        return;
      }

      if (newLabel !== "") {
        // Check for duplicate labels
        const isDuplicate = this.editorFields.some(
          (item, idx) => idx !== index && item.label.trim() === newLabel
        );

        if (isDuplicate) {
          this.$message.error(
            "Duplicate labels are not allowed; it has been set to the previous label."
          );
          // Reset to previous label
          this.$set(this.editorFields[index], "label", oldLabel);
        } else {
          console.log(oldLabel, currentLabelObj);
          const fieldToUpdate = this.editorFields.find(
            (field) => field.label.trim() === oldLabel
          );

          if (fieldToUpdate) {
            fieldToUpdate.label = newLabel;
            const oldLabelRegex = new RegExp(`\\[\\[${oldLabel}\\]\\]`, "g");
            const newLabelText = `[[${newLabel}]]`;
            this.editorContent = this.editorContent.replace(
              oldLabelRegex,
              newLabelText
            );
            currentLabelObj.fixedLabel = newLabel;
          } else {
            console.warn(`Field with label ${oldLabel} not found`);
          }
        }
      }
      this.previewEditorContent = this.editorContent;
    },
    handleLabelChange(index) {
      const currentLabelObj = this.filteredMapFilledByFieldLabels[index];
      const newLabel = currentLabelObj.label.trim();
      const oldLabel = currentLabelObj.fixedLabel.trim();

      // If the new label is the same as the old label, do nothing
      if (newLabel === oldLabel) {
        return;
      }

      if (newLabel !== "") {
        // Check for duplicate labels
        const isDuplicate = this.filteredMapFilledByFieldLabels.some(
          (item, idx) => idx !== index && item.label.trim() === newLabel
        );

        if (isDuplicate || newLabel === oldLabel) {
          this.$message.error(
            "Duplicate labels are not allowed; it has been set to the previous label."
          );
          // Reset to previous label
          this.$set(
            this.filteredMapFilledByFieldLabels[index],
            "label",
            oldLabel
          );
        } else {
          console.log(oldLabel, currentLabelObj);
          const fieldToUpdate = this.editorFields.find(
            (field) => field.label === oldLabel
          );

          if (fieldToUpdate) {
            fieldToUpdate.label = newLabel;
            const oldLabelRegex = new RegExp(`\\[\\[${oldLabel}\\]\\]`, "g");
            const newLabelText = `[[${newLabel}]]`;
            this.editorContent = this.editorContent.replace(
              oldLabelRegex,
              newLabelText
            );
            currentLabelObj.fixedLabel = newLabel;
          } else {
            console.warn(`Field with label ${oldLabel} not found`);
          }
        }
      }
    },
    mapFields() {
      this.loading = true;
      this.filteredMapFilledByFieldLabels.forEach((labelData, index) => {
        const filledBy = this.filledBySelections[index];
        if (!labelData.filled_by) {
          labelData.filled_by = filledBy ? filledBy : labelData.filled_by;
        }
        this.editorFields.forEach((field) => {
          if (field.id === labelData.id) {
            field.filled_by = labelData.filled_by;
            field.value = labelData.value;
            field.validations = labelData.validations;
          } else if (field.label.trim() === labelData.label.trim()) {
            field.filled_by = labelData.filled_by;
            field.value = labelData.value;
            field.validations = labelData.validations;
          }
        });
      });
      this.loading = false;
      this.closeFillingEditorFields();
    },
    async updateEditorContent(data) {
      this.filteredMapFilledByFieldLabels = [];
      if (data.isAppend) {
        this.editorContent += data.content;
      } else {
        this.editorContent = data.content;
      }
      this.editorFieldsUsingAI = data.AIFields;
      const filterededitorFieldsUsingAI = await this.replaceMatchingObjects(
        this.editorFieldsSelectedUsingAI,
        this.editorFieldsUsingAI
      );
      const placeholders = [
        ...this.editorContent.matchAll(/\[\[(.*?)\]\]/g),
      ].map((match) => match[1]);
      let filteredFields = [];
      filterededitorFieldsUsingAI.forEach((field) => {
        const fieldLabelOrName = field.label ? field.label : field.name;
        if (placeholders.includes(fieldLabelOrName)) {
          filteredFields.push(field);
        }
      });
      placeholders.forEach((placeholder) => {
        const fieldExists = filterededitorFieldsUsingAI.some(
          (field) => (field.label || field.name) === placeholder
        );
        if (!fieldExists) {
          let obj = {
            name: placeholder,
            type: "SINGLE_LINE_TEXT",
            required: true,
          };
          filteredFields.push(obj);
        }
      });
      this.unmatchedFieldLabels = [];
      let unmatchedFields = [];
      let docUsers = this.documentUsers
        ? this.documentUsers
        : this.documentRecipientsList;
      filteredFields.forEach((e) => {
        let keyName;
        if (e.name && !e.key) {
          keyName = e.name.replace(/\s+/g, "").toLowerCase();
        }
        let obj = {
          label: e.label ? e.label : e.name ? e.name : "",
          value: "",
          type: e.type ? e.type : "",
          allow_multiple: e.allow_multiple ? e.allow_multiple : false,
          filled_by: e.filled_by ? e.filled_by : docUsers[0].value,
          selected_user: e.filled_by,
          key:
            e.key && e.key !== ""
              ? e.key
              : e.name
              ? makePlainText(keyName)
              : makePlainText(e.label),
          is_required: true,
          input_type: e.input_type ? e.input_type : e.type ? e.type : "",
          max_selection: e.max_selection ? e.max_selection : 1,
          min_selection: e.min_selection ? e.min_selection : 1,
          id: e.id ? e.id : "",
          template_key: e.template_key ? e.template_key : e.key,
          template_id: e.template_id ? e.template_id : "",
          list_data: e.list_data ? e.list_data : [],
          validations: {
            currency: "USD",
            locale: "en-US",
            required: false,
            type: "NUMBER",
          },
          options: e.options ? e.options : [],
          isStatic: e.isStatic ? e.isStatic : false,
          step_time: e.step_time ? e.step_time : "",
          time_range: e.time_range ? e.time_range : [],
          fixedLabel: "",
          is_fixed: e.is_fixed ? e.is_fixed : false,
        };
        unmatchedFields.push(obj);
      });

      this.unmatchedFieldLabels.push(...unmatchedFields);
      this.aIgeneratedExtraFields = true;
      this.$nextTick(() => {
        this.$refs.unmatchedFieldsTable.toggleAllSelection();
      });
    },
    replaceMatchingObjects(editorFieldsSelectedUsingAI, editorFieldsUsingAI) {
      const editorFieldsSelectedUsingAIDict = Object.fromEntries(
        editorFieldsSelectedUsingAI.map((item) => [item.label, item])
      );
      return editorFieldsUsingAI.map((item) => {
        if (editorFieldsSelectedUsingAIDict[item.name]) {
          return { ...editorFieldsSelectedUsingAIDict[item.name] };
        } else {
          return { ...item };
        }
      });
    },
    handleFilledByChange(row) {
      if (
        !row.filled_by &&
        this.documentRecipientsList &&
        this.documentRecipientsList.length &&
        this.documentRecipientsList.length > 0
      ) {
        row.filled_by = this.documentRecipientsList[0].value;
      } else if (
        !row.filled_by &&
        this.documentUsers &&
        this.documentUsers.length &&
        this.documentUsers.length > 0
      ) {
        row.filled_by = this.documentUsers[0].value;
      }
    },
    handleTableSelectionChange(selection) {
      this.multipleSelectionOfAiExtraFields = [];
      if (selection && selection.length) {
        selection.forEach((item) => {
          if (item.filled_by === "") {
            this.$message.error(`Please select "Filled By" for ${item.label}`);
            this.multipleSelectionOfAiExtraFields.push(item);
          } else {
            this.multipleSelectionOfAiExtraFields.push(item);
          }
        });
      }
    },
    mapExtraFields() {
      let filteredFields = [];
      filteredFields = this.multipleSelectionOfAiExtraFields;
      this.multipleSelectionOfAiExtraFields = [];
      const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
      const labels = matches ? matches.map((label) => label.slice(2, -2)) : [];
      const labelArray = labels.reduce((array, label) => {
        array.push(label);
        return array;
      }, []);
      const labelCountMap = {};
      labelArray.forEach((label) => {
        labelCountMap[label] = (labelCountMap[label] || 0) + 1;
      });
      const newFields = [];
      Object.keys(labelCountMap).forEach((normalizedLabel) => {
        const count = labelCountMap[normalizedLabel];
        if (count > 0) {
          const existingField = filteredFields.find((field) => {
            const normalizedFieldLabel = field.label.trim();
            return normalizedFieldLabel === normalizedLabel;
          });
          if (existingField) {
            for (let i = 1; i < count; i++) {
              let name = existingField.name
                ? existingField.name
                : existingField.label;
              const keyName = name
                ? name.replace(/\s+/g, "_").toLowerCase()
                : "";
              const field = {
                label: existingField.label,
                value: existingField.value,
                type: existingField.type,
                allow_multiple: existingField.allow_multiple,
                filled_by: existingField.filled_by,
                selected_user: existingField.filled_by,
                key: keyName + "_" + i,
                is_required: existingField.is_required,
                input_type: existingField.input_type,
                max_selection: existingField.max_selection,
                min_selection: existingField.min_selection,
                id: existingField.id,
                template_key: existingField.template_key,
                template_id: existingField.template_id,
                list_data: existingField.list_data,
                validations: existingField.validations,
                options: existingField.options,
                isStatic: existingField.isStatic
                  ? existingField.isStatic
                  : false,
                step_time: existingField.step_time,
                time_range: existingField.time_range,
                fixedLabel: existingField.fixedLabel,
                is_fixed: existingField.is_fixed,
              };
              newFields.push(field);
            }
          }
        }
      });
      filteredFields.push(...newFields);
      if (!filteredFields.length) {
        this.$message.error("Please select the rows you want to map...!");
        return;
      }
      let hasEmptyFilledBy = false;
      filteredFields.forEach((item) => {
        if (item && item.filled_by === "") {
          this.$message.error(`Please select "Filled By" for ${item.label}`);
          hasEmptyFilledBy = true;
        }
      });
      if (hasEmptyFilledBy) {
        return;
      }
      if (!Array.isArray(this.editorFields) && !this.editorFields?.length) {
        this.editorFields = [];
      }
      this.editorFields = [...this.editorFields, ...filteredFields];
      const fieldLabels = matches
        ? matches.map((label) => this.normalizeLabel(label.slice(2, -2)))
        : [];
      const editorFieldLabels = this.editorFields.map((field) =>
        this.normalizeLabel(field.label)
      );
      const unmatchedFieldLabels = fieldLabels.filter((label) =>
        editorFieldLabels.includes(label)
      );
      const variableRegex = /\[\[(.*?)\]\]/g;
      this.editorContent = this.editorContent.replace(
        variableRegex,
        (match) => {
          const normalizedLabel = match
            .trim()
            .replace(/\s+/g, "_")
            .slice(2, -2);
          if (unmatchedFieldLabels.includes(normalizedLabel)) {
            return match;
          } else {
            const paragraphRegex = new RegExp(
              `<p[^>]*>.*?${match}.*?<\\/p>`,
              "gs"
            );
            this.editorContent = this.editorContent.replace(paragraphRegex, "");
            console.log(this.editorContent);
            return "";
          }
        }
      );
      this.aIgeneratedExtraFields = false;
    },
    updateValueOnlyIfSender(data) {
      if (this.getSignerUserTypeLength <= 1) {
        this.searchedMapLables.forEach((matchingField) => {
          if (matchingField.key === data.key) {
            if (
              data.value === null ||
              data.value === undefined ||
              data.value === ""
            ) {
              this.hasCkEditorEmptyFields = true;
            } else if (
              (matchingField.input_type === "SIGNATURE" ||
                matchingField.type === "SIGNATURE") &&
              this.getAuthenticatedUser &&
              this.getAuthenticatedUser.signature
            ) {
              matchingField.value = matchingField.isStatic
                ? this.getAuthenticatedUser.signature
                : matchingField.value;
            } else if (
              matchingField.input_type === "DATE_SIGNED" ||
              matchingField.type === "DATE_SIGNED"
            ) {
              let date = new Date();
              matchingField.value = data.value || date;
            } else if (
              matchingField.input_type === "IMAGE" ||
              matchingField.type === "IMAGE" ||
              matchingField.input_type === "FILE" ||
              matchingField.type === "FILE"
            ) {
              matchingField.value = data.value;
            } else if (
              matchingField.input_type === "CHECKBOX" ||
              matchingField.type === "CHECKBOX"
            ) {
              matchingField.value = data.value ? true : false;
            } else if (
              matchingField.input_type === "DATE_TIME" ||
              matchingField.type === "DATE_TIME"
            ) {
              const format = "MM-DD-YYYY HH:mm:ss";
              matchingField.value = moment(data.value).format(format);
            } else if (
              matchingField.input_type === "DATE_RANGE" ||
              matchingField.type === "DATE_RANGE"
            ) {
              const format = "MM-DD-YYYY";
              matchingField.value = data.value.map((date) =>
                moment(date).format(format)
              );
            } else if (
              matchingField.input_type === "TIME_RANGE" ||
              matchingField.type === "TIME_RANGE"
            ) {
              const format = "HH:mm:ss";
              matchingField.value = data.value.map((time) =>
                moment(time).format(format)
              );
            } else if (
              matchingField.input_type === "WEEKDAYS" ||
              matchingField.type === "WEEKDAYS"
            ) {
              let weekdays = this.weekdays_options.find(
                (e) => e.value === data.value
              );
              matchingField.value = weekdays ? weekdays.name : data.value;
            } else {
              matchingField.value = data.value;
            }
          }
        });
      }
    },
    editSenderData() {
      this.editorFields = [];
      this.editorFields = [
        ...new Set([...this.editorFields, ...this.searchedMapLables]),
      ];
      let signLength = this.editorFields.filter(
        (e) => e.input_type === "SIGNATURE" || e.type === "SIGNATURE"
      );
      this.editorFields.forEach((e) => {
        if (
          e &&
          e.input_type &&
          e.input_type === "CURRENCY" &&
          e.value === ""
        ) {
          e.value = 0;
        }
        if (
          e &&
          (e.isStatic === "true" || e.isStatic === true) &&
          ((e && e.input_type === "SIGNATURE") || e.type === "SIGNATURE")
        ) {
          e.value = this.getAuthenticatedUser.signature;
        }
        if (
          signLength &&
          signLength.length &&
          (e.input_type == "DATE_SIGNED" || e.type == "DATE_SIGNED") &&
          (e.filled_by === "SENDER" || e.selected_user === "SENDER")
        ) {
          let date = new Date();
          e.value = date;
        }
      });
      this.changeFilledByModal = true;
      this.previewEditorContentModal = false;
    },
    async mapUserFieldsFilledBy() {
      try {
        if (this?.editorFields?.length === 0) {
          this.$message.error("Please add fields to fill data...!");
          console.log('1,1')
          return;
        }
        this.documentDataLoading = true;
        this.loadingText = "Fetching Fields Data...";
        let usersVal = this.documentRecipientsList || this.documentUsers;
        let isCtypeIsReceiver = false;
        if (
          this.$route.name !== "edit-configure-document-image" &&
          this.$route.name !== "edit-configure-template-image"
        ) {
          this.checkAllUsersAssigned = (usersVal || []).filter((user) => {
            return user && !user.email;
          });
        }
        const allUsersValid = usersVal.every(
          (user) =>
            user.contact_type === "62b4145c3fd6fa779848accd" ||
            user?.contact_type?._id === "62b4145c3fd6fa779848accd" ||
            user.constant_user === true
        );
        let filteredUsers 
        if (!allUsersValid) {
          console.log('1,3')
          filteredUsers = []
        } else {
          console.log('1,4')
           filteredUsers = usersVal.filter(
            (user) =>
              user.type !== "SENDER" &&
              user.contact_type !== "62b4145c3fd6fa779848accd" &&
              user?.contact_type?._id !== "62b4145c3fd6fa779848accd" &&
              user.constant_user !== true
          );
        
          console.log(filteredUsers);
        }
        console.log('this.$route.name',this.$route.name,this.$route)
        if (
          this.$route &&
          this.$route.name &&
          this.$route.name.trim() === 'employee-documents-custom-document-edit' &&
          (this.checkAllUsersAssigned.length !== 0 ||
            filteredUsers.length !== 0)
        ) {
          this.$message.error("Please add email to fetch user data");
          this.documentDataLoading = false
          return;
        }
        await this.checkIsCheckBoxGroup();
        await this.checkPrimaryFieldsAndAddDataInCk();
        if (!isCtypeIsReceiver) {
          this.documentDataLoading = true;
          this.loadingText = "Fetching Entity Fields Data...";
          await this.setEntityDataToFieldForCk();
          this.documentDataLoading = false;
        }
        this.documentDataLoading = true;
        this.loadingText = "Hang tight! You are almost there...";
        const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
        const fieldLabels = matches
          ? matches.map((label) => label.slice(2, -2))
          : [];
        this.filledBySelections = [];
        let mapFilledByLabelsAndValues = [];
        this.documentDataLoading = false;
        fieldLabels.forEach((label, index) => {
          let obj = {
            label: label ? label : "",
            value: "",
            type: "",
            list_data: [],
            input_type: "",
            max_selection: 1,
            min_selection: 1,
            allow_multiple: false,
            validations: {
              currency: "USD",
              locale: "en-US",
              required: false,
              type: "NUMBER",
            },
            options: [],
            isStatic: false,
            fixedLabel: "",
            selected_user: "",
            is_required: true,
            step_time: "",
            time_range: [],
            is_fixed: false,
          };
          this.editorFields.forEach((e) => {
            const normalizedVariableName = label.trim().replace(/\s+/g, " ");
            if (
              e.label.trim().replace(/\s+/g, " ") === normalizedVariableName
            ) {
              //if(index === i){
              this.filledBySelections[index] = e.filled_by;
              //}
              obj = {
                label: e.label,
                value: e.value,
                type: e.input_type ? e.input_type : e.type,
                input_type: e.input_type ? e.input_type : e.type,
                list_data: e.list_data,
                key: e.key ? e.key : makePlainText(e.label),
                max_selection: e.max_selection ? e.max_selection : 1,
                min_selection: e.min_selection ? e.min_selection : 1,
                allow_multiple: e.allow_multiple ? e.allow_multiple : false,
                options: e.options,
                isStatic: e.isStatic,
                id: e.id ? e.id : e.key,
                template_key: e.template_key ? e.template_key : e.key,
                fixedLabel: e.label,
                selected_user: e.selected_user,
                is_required: e.is_required,
                template_id: e.template_id ? e.template_id : e.id,
                validations: e.validations ? e.validations : obj.validations,
                step_time: e.step_time ? e.step_time : obj.step_time,
                time_range: e.time_range ? e.time_range : obj.time_range,
                is_fixed: e.is_fixed ? e.is_fixed : false,
              };
            }
          });

          mapFilledByLabelsAndValues.push({ ...obj });
        });
        this.filteredMapFilledByFieldLabels = mapFilledByLabelsAndValues;
        this.searchedMapLables = mapFilledByLabelsAndValues;
        this.documentDataLoading = false;
        if (this.getSignerUserTypeLength > 1) {
          this.changeFilledByModal = true;
          this.documentDataLoading = false;
        }
      } catch (err) {
        this.documentDataLoading = false;
        this.$message.error(err);
      }
    },
    setRowHeight() {
      return {
        height: "5px",
      };
    },
    async fetchAllDocuments() {
      this.loading = true;
      await this.$store.commit(
        "documents/setDocumentFormFieldsData",
        {},
        {
          root: true,
        }
      );
      this.pageCount = 1;
      this.currentDoc = 0;
      let allDocs = this.files.length;
      while (this.currentDoc < allDocs) {
        let file = this.files[this.currentDoc];
        if (this.isMergeDocument) {
          await this.getAllPdfFieldsForSingleDocument(file);
        } else {
          await this.getAllPdfFields(file, this.currentDoc);
        }
      }
      this.loading = false;
    },
    findValueByKey(array, key, data) {
      if (!data.isStatic) {
        const [templateId, field] = key && key.split("#");
        const foundItem = array.find((item) => item.template_id === templateId);
        if (data && data.input_type && data.input_type === "IMAGE") {
          if (
            foundItem &&
            foundItem.template_data &&
            foundItem.template_data[field]
          ) {
            return foundItem.template_data[field];
          } else {
            return data.image_url;
          }
        }
        if (
          foundItem &&
          foundItem.template_data &&
          foundItem.template_data[field]
        ) {
          return foundItem.template_data[field];
        }
      }
    },

    allStaticFieldsEditor() {
      let fields = [];
      let data = [
        {
          label: `${this.recipientSelection}@Single Line Text`,
          isStatic: true,
          template_key: `${this.recipientSelection}@${makePlainText(
            "Single_Line_Text"
          )}`,
          type: `${"SINGLE_LINE_TEXT"}`,
          width: 200,
          key: `${this.recipientSelection}@${makePlainText(
            "Single_Line_Text"
          )}`,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Number`,
          isStatic: true,
          template_key: `${this.recipientSelection}@${makePlainText("Number")}`,
          type: `${"NUMBER"}`,
          width: 200,
          key: `${this.recipientSelection}@${makePlainText("Number")}`,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Date`,
          isStatic: true,
          template_key: `${this.recipientSelection}@${makePlainText("Date")}`,
          type: `${"DATE"}`,
          key: `${this.recipientSelection}@${makePlainText("Date")}`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Single Line Content`,
          isStatic: true,
          template_key: `${this.recipientSelection}@${makePlainText(
            "Single_Line_Content"
          )}`,
          type: `${"SINGLE_LINE_CONTENT"}`,
          key: `${this.recipientSelection}@${makePlainText(
            "Single_Line_Content"
          )}`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Signature`,
          isStatic: true,
          template_key: `${this.recipientSelection}@${makePlainText(
            "signature"
          )}`,
          key: `${this.recipientSelection}@${makePlainText("signature")}`,
          type: `${"SIGNATURE"}`,
          width: 200,
          height: 46,
        },
        {
          label: `${this.recipientSelection}@Date Signed`,
          isStatic: true,
          template_key: `${this.recipientSelection}@${makePlainText(
            "date_signed"
          )}`,
          type: `${"DATE_SIGNED"}`,
          key: `${this.recipientSelection}@${makePlainText("date_signed")}`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Currency`,
          isStatic: true,
          template_key: `${this.recipientSelection}@${makePlainText(
            "currency"
          )}`,
          key: `${this.recipientSelection}@${makePlainText("currency")}`,
          type: `${"CURRENCY"}`,
          width: 200,
          height: 24,
          validations: {
            currency: "USD",
            locale: "en-US",
            required: false,
            type: "NUMBER",
          },
          value: 0,
        },
        // {
        //   label: "Initial",
        //   isStatic: true,
        //   template_key: `${this.recipientSelection}@${makePlainText(
        //     "initial"
        //   )}`,
        //   type: `${"INITIAL"}`,
        //   width: 200,
        //   height: 46,
        // },
      ];
      data.forEach((item) => {
        let obj = {
          label: item.label,
          template_key: item.template_key,
          key: item.key,
          id: item.template_key,
          input_type: item.type,
          value: null,
          filled_by: this.recipientSelection,
          selected_user: this.recipientSelection,
          is_required: item.is_required,
          width: item.width,
          height: item.height,
          list_data: [],
          validations: {
            currency: "USD",
            locale: "en-US",
            required: false,
            type: "NUMBER",
          },
          isStatic: true,
          options: [],
        };
        fields.push(obj);
      });
      if (!Array.isArray(this.allMentionableFields)) {
        this.allMentionableFields = [];
      }
      this.allMentionableFields = [...this.allMentionableFields, ...fields];
    },
    setDefaultSelection() {
      let docUsers = this.documentUsers
        ? this.documentUsers
        : this.documentRecipientsList;

      docUsers.forEach((e, index) => {
        if (e && e.value === "SENDER") {
          this.$set(this.filledBySelections, index, e.value);
        }
      });
    },
    allPrimaryFieldsSender(cTypeData) {
      let fields = [];
      let data = [
        {
          label: `${this.recipientSelection}@First Name`,
          template_key: `${this.recipientSelection}@${makePlainText(
            "First_Name"
          )}`,
          inputType: `${"PRIMARY_FIELDS"}@${makePlainText("First_Name")}`,
          input_type: `${"PRIMARY_FIELDS"}@${makePlainText("First_Name")}`,
          key: `${"PRIMARY_FIELDS"}@${makePlainText("First_Name")}@${
            this.recipientSelection
          }`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Last Name`,
          template_key: `${this.recipientSelection}@${makePlainText(
            "Last_Name"
          )}`,
          inputType: `${"PRIMARY_FIELDS"}@${makePlainText("Last_Name")}`,
          input_type: `${"PRIMARY_FIELDS"}@${makePlainText("Last_Name")}`,
          key: `${"PRIMARY_FIELDS"}@${makePlainText("Last_Name")}@${
            this.recipientSelection
          }`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Phone`,
          template_key: `${this.recipientSelection}@${makePlainText("Phone")}`,
          inputType: `${"PRIMARY_FIELDS"}@${makePlainText("Phone")}`,
          input_type: `${"PRIMARY_FIELDS"}@${makePlainText("Phone")}`,
          key: `${"PRIMARY_FIELDS"}@${makePlainText("Phone")}@${
            this.recipientSelection
          }`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Email`,
          template_key: `${this.recipientSelection}@${makePlainText("Email")}`,
          inputType: `${"PRIMARY_FIELDS"}@${makePlainText("Email")}`,
          input_type: `${"PRIMARY_FIELDS"}@${makePlainText("Email")}`,
          key: `${"PRIMARY_FIELDS"}@${makePlainText("Email")}@${
            this.recipientSelection
          }`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Address`,
          template_key: `${this.recipientSelection}@${makePlainText(
            "Address"
          )}`,
          inputType: `${"PRIMARY_FIELDS"}@${makePlainText("Address")}`,
          input_type: `${"PRIMARY_FIELDS"}@${makePlainText("Address")}`,
          key: `${"PRIMARY_FIELDS"}@${makePlainText("Address")}@${
            this.recipientSelection
          }`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Company`,
          template_key: `${this.recipientSelection}@${makePlainText(
            "Company"
          )}`,
          inputType: `${"PRIMARY_FIELDS"}@${makePlainText("Company")}`,
          input_type: `${"PRIMARY_FIELDS"}@${makePlainText("Company")}`,
          key: `${"PRIMARY_FIELDS"}@${makePlainText("Company")}@${
            this.recipientSelection
          }`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Full Name`,
          template_key: `${this.recipientSelection}@${makePlainText(
            "Full_Name"
          )}`,
          inputType: `${"PRIMARY_FIELDS"}@${makePlainText("Full_Name")}`,
          input_type: `${"PRIMARY_FIELDS"}@${makePlainText("Full_Name")}`,
          key: `${"PRIMARY_FIELDS"}@${makePlainText("Full_Name")}@${
            this.recipientSelection
          }`,
          width: 200,
          height: 24,
        },
        {
          label: `${this.recipientSelection}@Title`,
          template_key: `${this.recipientSelection}@${makePlainText("Title")}`,
          inputType: `${"PRIMARY_FIELDS"}@${makePlainText("Title")}`,
          input_type: `${"PRIMARY_FIELDS"}@${makePlainText("Title")}`,
          key: `${"PRIMARY_FIELDS"}@${makePlainText("Title")}@${
            this.recipientSelection
          }`,
          width: 200,
          height: 24,
        },
      ];
      data.forEach((item) => {
        let obj = {
          filled_by: this.recipientSelection,
          selected_user: this.recipientSelection,
          is_required: item.is_required,
          contact_type: cTypeData ? cTypeData._id : "",
          label: item.label,
          template_key: item.template_key,
          id: item.template_key,
          input_type: item.inputType,
          value: null,
          width: item.width,
          key: item.key,
          height: item.height,
          list_data: [],
          validations: {
            currency: "USD",
            locale: "en-US",
            required: false,
            type: "NUMBER",
          },
          options: [],
        };
        fields.push(obj);
      });
      if (!Array.isArray(this.allMentionableFields)) {
        this.allMentionableFields = [];
      }

      this.allMentionableFields = [...this.allMentionableFields, ...fields];
    },
    partsLabke(data) {
      const parts = data.label.split("-");

      // Trim whitespace from each part and return the last part
      return parts.length > 0
        ? parts[parts.length - 1].trim()
        : data.labelCountMap;
    },
    async getEntityDetails() {
      this.loading = true;
      this.loadingText = "Fetching entity Fields...";
      await this.companyEntityDetails();
      this.allMentionableFields = [];
      let docUsers = this.documentRecipientsList || this.documentUsers;
      let user = (docUsers || []).find(
        (e) => e.value === this.recipientSelection
      );
      this.contactTypeEntityId =
        this.recipientSelection === "SENDER"
          ? user.contact_type
          : this.contactTypeEntityId && this.contactTypeEntityId !== null
          ? typeof this.contactTypeEntityId !== "object"
            ? this.contactTypeEntityId
            : this.contactTypeEntityId._id
          : null;
      if (this.contactTypeEntityId) {
        this.allMentionableFields = await this.fetchEntityDetails(
          this.contactTypeEntityId,
          true
        );
        this.loading = false;
      }
      let d1 = [];
      //let user = docUsers.find((user) => user.value === this.recipientSelection);
      docUsers.forEach((user) => {
        let seletedUserTemplateData = [];
        if (this.getTemplatesData && this.getTemplatesData.length) {
          seletedUserTemplateData = this.getTemplatesData.filter(
            (e) =>
              (user.entity_data_id &&
                user.entity_data_id._id &&
                e.entity_data_id == user.entity_data_id._id) ||
              e.entity_data_id == user.entity_data_id
          );
        }
        let da = [];
        if (this.allMentionableFields && this.allMentionableFields.length) {
          da = seletedUserTemplateData.filter((e) =>
            this.allMentionableFields.some(
              (el) => el.template_id === e.template_id
            )
          );
        }
        d1 = da;
      });
      this.allSelectedEntityMentionFields = this.allMentionableFields;
      if (this.allMentionableFields) {
        this.allMentionableFields = this.allMentionableFields
          .filter((item) => {
            const excludedTypes = [
              "RADIO",
              "YES_OR_NO",
              "PAY_BUTTON",
              "FILE",
              "DATA_TABLE",
              "ENTITY_VARIABLE",
              "ENTITY",
              "PARAGRAPH",
              "HORIZONTAL_LINE",
              "HTML",
            ];
            return !excludedTypes.includes(
              item.input_type || item.inputType || item.type
            );
          })
          .map((item) => ({
            label:
              item.input_type === "HEADING" ||
              item.input_type === "PARAGRAPH" ||
              item.input_type === "SINGLE_LINE_CONTENT"
                ? item.input_type
                : item.label,
            template_key: item.template_key,
            id: this.recipientSelection + "@" + item.template_key,
            key: this.recipientSelection + "@" + item.key,
            template_id: item.template_id,
            field_type: item.field_type,
            name: this.partsLabke(item),
            input_type: item.inputType,
            filled_by: this.recipientSelection,
            selected_user: this.recipientSelection,
            is_required: item.is_required,
            max_selection: item.max_selection ? item.max_selection : 1,
            min_selection: item.min_selection ? item.min_selection : 1,
            allow_multiple: item.allow_multiple ? item.allow_multiple : false,
            value:
              item.input_type === "HEADING"
                ? item.orgLabel
                : item.input_type === "PARAGRAPH" ||
                  item.input_type === "SINGLE_LINE_CONTENT"
                ? item.content
                  ? item.content
                  : item.label.split("-")[1]
                : item.value !== undefined &&
                  item.value !== null &&
                  item.value !== ""
                ? item.value
                : this.findValueByKey(d1, item.template_key, item),

            width: item.width,
            list_data: item.list_data ? item.list_data : [],
            options: item.options ? item.options : [],
            height: item.height,
            validations: item.validations ? item.validations : {},
            step_time: item.properties.step_time
              ? item.properties.step_time
              : [],
            time_range: item.properties.time_range
              ? item.properties.time_range
              : [],
            is_fixed: item.is_fixed ? item.is_fixed : false,
          }));
      }
      let entityType = docUsers.find(
        (e) => e && e.value && e.value === this.recipientSelection
      );
      let BusinessEnityObj = await this.getEntityType(entityType);
      if (
        (entityType &&
          entityType.value !== "SENDER" &&
          entityType.contact_type &&
          entityType.contact_type.entity_type &&
          entityType.contact_type.entity_type !== "INDIVIDUAL") ||
        (BusinessEnityObj &&
          BusinessEnityObj.entity_type &&
          BusinessEnityObj.entity_type === "BUSINESS")
      ) {
        await this.allPrimaryFieldsSender(BusinessEnityObj);
      } else if (
        entityType &&
        entityType.value &&
        entityType.value === "SENDER"
      ) {
        await this.allPrimaryFieldsSender(BusinessEnityObj);
      } else if (
        entityType &&
        entityType.contact_type &&
        (entityType.contact_type?._id === "62b4145c3fd6fa779848accd" ||
          entityType.contact_type === "62b4145c3fd6fa779848accd")
      ) {
        await this.allPrimaryFieldsSender(BusinessEnityObj);
      }
      await this.allStaticFieldsEditor(this.allMentionableFields);
      this.loading = false;
    },
    getEntityType(cType) {
      let contactObj;
      if (
        this.allEntities &&
        (this.allEntities.length || this.allEntities.data?.length)
      ) {
        const allDat = this.allEntities.data || this.allEntities;
        const id = cType?.contact_type?._id || cType?.contact_type;
        contactObj = allDat.find((e) => e._id === id);
      }
      return contactObj;
    },
    generateMappingKeysEditorFields(objects) {
      const mappingKeysEditorFields = {};

      objects.forEach((obj, index) => {
        const uniqueKey = `${obj.label}_${index}`;
        mappingKeysEditorFields[uniqueKey] = {
          key: uniqueKey,
          label: `${obj.label}_${index}`,
          template_key: `${obj.template_key}_${index}`,
        };
      });

      return mappingKeysEditorFields;
    },
    async calculateXandYPositionsForCompanyData(item) {
      let docUsers = this.documentRecipientsList
        ? this.documentRecipientsList
        : this.documentUsers;
      let matchingField = this.allMentionableFields.find(
        (field) => field.label === item.label
      );

      if (matchingField) {
        item.list_data = matchingField.list_data;
        item.options = matchingField.options;
        item.validations = matchingField.validations;
        item.max_selection = matchingField.max_selection
          ? matchingField.max_selection
          : 1;
        item.min_selection = matchingField.min_selection
          ? matchingField.min_selection
          : 1;
        item.allow_multiple = item.allow_multiple ? item.allow_multiple : false;
      }
      let user = docUsers.find(
        (e) => e.value === this.recipientSelection && e.user_type === "CC"
      );
      if (user) {
        item.filled_by = "SENDER";
      }
      if (
        (matchingField && !matchingField.filled_by) ||
        (item && item.filled_by)
      ) {
        let user = docUsers.find(
          (e) => e.value === this.recipientSelection && e.user_type === "CC"
        );
        if (user) {
          item.filled_by = "SENDER";
        } else {
          item.filled_by = this.recipientSelection;
          item.selected_user = this.recipientSelection;
        }
      }
      this.editorFields.push(item);
    },
    async calculateXandYPositions(item) {
      let docUsers = this.documentRecipientsList
        ? this.documentRecipientsList
        : this.documentUsers;
      let matchingField = this.allMentionableFields.find(
        (field) => field.label === item.label
      );
      if (matchingField) {
        item.list_data = matchingField.list_data;
        item.options = matchingField.options;
        item.validations = matchingField.validations;
        item.fixedLabel = matchingField.label;
        item.max_selection = matchingField.max_selection
          ? matchingField.max_selection
          : 1;
        item.min_selection = matchingField.min_selection
          ? matchingField.min_selection
          : 1;
        item.allow_multiple = item.allow_multiple ? item.allow_multiple : false;
        item.template_key = matchingField.template_key;
        item.template_id = matchingField.template_id;
        item.key = matchingField.key;
        item.id = matchingField.id;
        item.is_required = true;
        item.step_time = matchingField.step_time ? matchingField.step_time : "";
        item.time_range = matchingField.time_range
          ? matchingField.time_range
          : [];
        item.is_fixed = matchingField.is_fixed ? matchingField.is_fixed : false;
        item.value = "";
      }
      let user = docUsers.find(
        (e) => e.value === this.recipientSelection && e.user_type === "CC"
      );
      if (user) {
        item.filled_by = "SENDER";
      }
      if (!matchingField.filled_by || item.filled_by) {
        let user = docUsers.find(
          (e) => e.value === this.recipientSelection && e.user_type === "CC"
        );
        if (user) {
          item.filled_by = "SENDER";
        } else {
          item.filled_by = this.recipientSelection;
        }
      }
      if (
        item &&
        item.input_type === "CHECKBOX_GROUP" &&
        (item.value === null || item.value === "" || item.value === undefined)
      ) {
        item.value = [];
      }
      if (this.showChatbot) {
        this.editorFieldsSelectedUsingAI = [
          ...this.editorFieldsSelectedUsingAI,
          item,
        ];
      } else {
        this.editorFields = [...this.editorFields, item];
      }
    },
    async setEntityDataToFieldForCk() {
      this.loading = true;
      this.loadingText =
        "Mapping mentioned fields with user entity fields data...";
      let userVal = this.documentRecipientsList || this.documentUsers;
      let userTemplateData = [];
      let eDataIds = userVal
        .filter((e) => e?.entity_data_id || e?.contact_type)
        .map((e) => {
          if (typeof e?.entity_data_id === "string") {
            return e.entity_data_id;
          } else if (e?.entity_data_id?._id) {
            return e.entity_data_id._id;
          } else if (typeof e?.contact_type === "string") {
            return e.contact_type;
          } else if (e?.contact_type?._id) {
            return e.contact_type._id;
          }
          return null;
        })
        .filter((id) => id);
      for (let user of userVal) {
        let cType = "";
        this.mentionData = [];
        this.templatesDataInCk = [];
        if (user?.contact_type) {
          cType =
            typeof user.contact_type === "string"
              ? user.contact_type
              : user.contact_type._id;
        } else {
          cType = user.entity_data_id?.entity_id || user.entity_data_id;
        }

        if (cType) {
          this.mentionData = await this.fetchEntityDetails(cType, true);
        }
        if (eDataIds && eDataIds.length) {
          let params = {
            entity_data_ids: eDataIds,
          };
          let response = await postAPICall("GET", "/templates-data", params);
          this.templatesDataInCk = response.data || response.data.data;
        }
        this.loading = false;
        userTemplateData = this.templatesDataInCk.filter((template) => {
          const userEntityId = user.entity_data_id?._id || user.entity_data_id;
          const userContactType = user.contact_type?._id || user.contact_type;

          if (template.entity_data_id === userEntityId) {
            return true;
          }
          if (template.entity_data_id?._id === userEntityId) {
            return true;
          }
          if (template.contact_type === userContactType) {
            return true;
          }
          if (template.contact_type?._id === userContactType) {
            return true;
          }
          return false;
        });
        this.loading = false;
      }
      for (let template of userTemplateData) {
        for (let field of this.editorFields) {
          let key = field.key.includes("@")
            ? field.key.split("@")[1].trim()
            : field.key.trim();
          if (
            template &&
            template.template_data &&
            template.template_data[key] &&
            field.template_id &&
            field.template_id === template.template_id
          ) {
            if (template.template_data[key] !== "") {
              field.value =
                field.value !== "" ? field.value : template.template_data[key];
            } else {
              field.value = "";
            }
          }
        }
      }
    },
    replaceUnderscoresWithSpaces(str) {
      return str.replace(/_/g, " ");
    },
    checkIsCheckBoxGroup() {
      this.editorFields.forEach((field) => {
        if (!field || !field.input_type) return;
        if (field && field.input_type === "CURRENCY") {
          if (
            field.value === "" ||
            field.value === undefined ||
            field.value === null
          ) {
            field.value = 0;
          }
        }
        if (field.input_type === "CHECKBOX_GROUP") {
          if (typeof field.value === "boolean") {
            field.value = [];
            this.$set(field, "value", []);
          } else if (typeof field.value === "string") {
            field.value = field.value.split(",").map((item) => item.trim());
            this.$set(field, "value", field.value);
          }
          return;
        }
        if (field.input_type === "TIME_RANGE") {
          if (
            field.value.length === 0 ||
            field.value === undefined ||
            field.value === null
          ) {
            field.value = "";
          } else {
            field.value =
              typeof field.value === "string" && field.value.trim() !== ""
                ? field.value.split(",")
                : [];
            const format = "HH:mm:ss";
            let timeOnlyRegex = /^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/;
            let newAr = [];
            for (let i = 0; i < field.value.length; i++) {
              let val = "";
              if (timeOnlyRegex.test(field.value[i])) {
                val = field.value[i];
              } else {
                val = moment(field.value[i]).format(format);
              }
              newAr.push(val);
            }
            field.value = newAr.join(" To ");
          }
        }
        const needsArrayConversion = [
          "DATE_RANGE",
          "DATE_TIME_RANGE",
          "MULTI_SELECT",
        ].includes(field.input_type);
        if (
          needsArrayConversion &&
          (!field.value ||
            typeof field.value === "string" ||
            field.value === false)
        ) {
          field.value =
            typeof field.value === "string" && field.value.trim() !== ""
              ? field.value.split(",")
              : [];

          this.$set(field, "value", field.value);
          return;
        }
        return field.value;
      });
    },
    async checkPrimaryFieldsAndAddDataInCk(usersInWorkflow) {
      try {
        this.loading = true;
        this.loadingText = "Fetching Primary Fields Data...";
        if (!this.editorFields || !Array.isArray(this.editorFields)) {
          console.error("editorFields is not defined or not an array");
          return;
        }
        let docUsers =
          this.documentRecipientsList || this.documentUsers || usersInWorkflow;
        if (!this.form) {
          this.form = {};
        }
        this.editorFields.forEach((matchingField) => {
          if (
            matchingField &&
            (matchingField.template_id === undefined ||
              matchingField.template_id === "")
          ) {
            if (!matchingField.template_key) {
              return;
            }
            for (let i = 0; i < docUsers.length; i++) {
              const {
                first_name,
                last_name,
                address,
                phone,
                company_name,
                email,
                title,
              } = docUsers[i];
              const templateKeyParts = matchingField.template_key.split("@");
              const fieldType = templateKeyParts[1];
              if (
                fieldType &&
                matchingField.isStatic === true &&
                matchingField.fixedLabel
                  ? matchingField.fixedLabel.split("@")[0] === docUsers[i].value
                  : matchingField.id.split("@")[0] === docUsers[i].value
              ) {
                switch (fieldType) {
                  case "first_name":
                    this.form[matchingField.key] = matchingField.value =
                      matchingField.value === ""
                        ? first_name
                        : matchingField.value
                        ? matchingField.value
                        : "";
                    break;
                  case "last_name":
                    this.form[matchingField.key] = matchingField.value =
                      matchingField.value === ""
                        ? last_name
                        : matchingField.value
                        ? matchingField.value
                        : "";
                    break;
                  case "address":
                    this.form[matchingField.key] = matchingField.value =
                      matchingField.value === ""
                        ? address
                        : matchingField.value
                        ? matchingField.value
                        : "";
                    break;
                  case "phone":
                    this.form[matchingField.key] = matchingField.value =
                      matchingField.value === ""
                        ? phone
                        : matchingField.value
                        ? matchingField.value
                        : "";

                    break;
                  case "company":
                    this.form[matchingField.key] = matchingField.value =
                      matchingField.value === ""
                        ? company_name
                        : matchingField.value
                        ? matchingField.value
                        : "";

                    break;
                  case "email":
                    this.form[matchingField.key] = matchingField.value =
                      matchingField.value === ""
                        ? email
                        : matchingField.value
                        ? matchingField.value
                        : "";
                    break;
                  case "full_name":
                    this.form[matchingField.key] = matchingField.value =
                      first_name || last_name
                        ? `${first_name} ${last_name}`
                        : matchingField.value
                        ? matchingField.value
                        : "";
                    break;
                  case "title":
                    this.form[matchingField.key] =
                      title || matchingField.value || "";
                    matchingField.value = title || matchingField.value || "";
                    break;
                  default:
                    this.form[matchingField.key] = matchingField.value = "";
                    break;
                }
              }
            }
          }
        });
        this.loading = false;
      } catch (error) {
        console.error(
          "An error occurred in checkPrimaryFieldsAndAddData:",
          error
        );
        this.loading = false;
      }
    },
    normalizeLabel(label) {
      return label.trim().replace(/\s+/g, "_");
    },
    async finishFromModal() {
      this.documentDataLoading = true;
      this.loadingText = "Fetching Fields Data...";
      await this.mapUserFieldsFilledBy();
      let usersInDoc = this.documentRecipientsList
        ? this.documentRecipientsList
        : this.documentUsers;
      const hasEmptyEmail = usersInDoc.some((obj) => !obj.email);
      if (hasEmptyEmail) {
        this.$message.error(
          "Please assign recipient to the selected contact type...!"
        );
        this.loading = false;
        return;
      } else {
        const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
        const labels = matches
          ? matches.map((label) => this.normalizeLabel(label.slice(2, -2)))
          : [];
        const labelCountMap = {};
        labels.forEach((label) => {
          if (labelCountMap[label]) {
            labelCountMap[label] += 1;
          } else {
            labelCountMap[label] = 1;
          }
        });
        const filteredEditorFields = [];
        this.editorFields.forEach((field) => {
          const normalizedFieldLabel = this.normalizeLabel(field.label);
          if (labelCountMap[normalizedFieldLabel] > 0) {
            for (let i = 0; i < labelCountMap[normalizedFieldLabel]; i++) {
              filteredEditorFields.push(field);
            }
            labelCountMap[normalizedFieldLabel] = 0; // Reset the count to 0 after processing
          }
        });
        this.editorFields = filteredEditorFields;
        let signLength = this.editorFields.filter(
          (e) =>
            //  ((e.isStatic && e.isStatic === "true") || e.isStatic === true) &&
            e.input_type === "SIGNATURE" || e.type === "SIGNATURE"
        );
        if (this.editorFields && this.editorFields.length) {
          this.editorFields.forEach((e) => {
            if (
              e &&
              e.input_type &&
              e.input_type === "CURRENCY" &&
              e.value === ""
            ) {
              e.value = 0;
            }
            if (
              (e &&
                (e.isStatic === "true" || e.isStatic === true) &&
                ((e && e.input_type === "SIGNATURE") ||
                  e.type === "SIGNATURE")) ||
              (e &&
                (e.input_type === "SIGNATURE" || e.type === "SIGNATURE") &&
                e.template_id.includes("#") &&
                e.value === "")
            ) {
              e.value = this.getAuthenticatedUser.signature;
            }
            if (
              signLength &&
              signLength.length &&
              (e.input_type == "DATE_SIGNED" || e.type == "DATE_SIGNED") &&
              e.filled_by === "SENDER"
            ) {
              let date = new Date();
              e.value = date;
            }
          });
          this.changeFilledByModal = true;
        }
        this.filteredResults = this.editorFields;
        this.documentDataLoading = false;
      }
    },
    mapSenderSideFinishFIelds() {
      this.loading = true;
      if (this.searchedLabel && this.searchedLabel.length) {
        this.editorFields = this.filteredResults;
      }
      this.editorFields.forEach((labelData, index) => {
        const filledBy = this.filledBySelections[index];
        if (!labelData.filled_by) {
          labelData.filled_by = filledBy ? filledBy : labelData.filled_by;
        }
        this.editorFields.forEach((field) => {
          if (field.label === labelData.label) {
            field.filled_by = labelData.filled_by;
            field.value = labelData.value;
            field.validations = labelData.validations;
          }
        });
      });
      this.loading = false;
      this.changeFilledByModal = false;
    },
    async finishCkEditorDocument() {
      try {
        await this.mapSenderSideFinishFIelds();
        let usersInDoc = this.documentRecipientsList
          ? this.documentRecipientsList
          : this.documentUsers;
        const hasEmptyEmail = usersInDoc.some((obj) => !obj.email);
        if (hasEmptyEmail) {
          this.$message.error(
            "Please assign recipient to the selected contact type...!"
          );
          this.loading = false;
          return;
        } else {
          if (
            this.$route &&
            this.$route.name === "employee-documents-custom-document-edit"
          ) {
            let newFields = [...this.editorFields];
            const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
            const fieldLabels = matches
              ? matches.map((label) => this.normalizeLabel(label.slice(2, -2)))
              : [];
            const labelCountMap = {};
            fieldLabels.forEach((label) => {
              if (labelCountMap[label]) {
                labelCountMap[label] += 1;
              } else {
                labelCountMap[label] = 1;
              }
            });
            const filteredEditorFields = [];
            newFields.forEach((field) => {
              const normalizedFieldLabel = this.normalizeLabel(field.label);
              if (labelCountMap[normalizedFieldLabel] > 0) {
                for (let i = 0; i < labelCountMap[normalizedFieldLabel]; i++) {
                  filteredEditorFields.push(field);
                }
                labelCountMap[normalizedFieldLabel] = 0;
              }
            });
            newFields = filteredEditorFields;
            this.editorFields = [];
            const regex = /\[\[([^\]]+)\]\]/g;
            let labels = [];
            let match;
            while ((match = regex.exec(this.editorContent)) !== null) {
              labels.push(match[1]);
            }
            if (
              newFields &&
              newFields.length &&
              labels &&
              labels.length &&
              newFields.length !== labels.length
            ) {
              labels.forEach((label) => {
                const matchingFields = this.allMentionableFields.filter(
                  (field) => field.label === label
                );
                matchingFields.forEach((field) => {
                  this.editorFields.push(field);
                });
              });
            }
            this.editorFields = [...this.editorFields, ...newFields];
          }
          this.checkSignerUsers = this.documentRecipientsList
            ? this.documentRecipientsList
            : this.documentUsers;
          if (!this.checkSignerUsers.some((e) => e.user_type === "SIGNER")) {
            this.checkSignerUsers = this.checkSignerUsers.filter(
              (e) => e && e.user_type === "CC" && e.type === "RECEIVER"
            );
          }
          this.loading = true;
          this.loadingText = "Generating Document...";
          this.fillingFields = [];
          this.elements = [];
          this.senderFields = [];
          this.receiverFields = [];
          this.mappedFieldsOfUsers = [];
          let dtatat;
          if (
            this.$route &&
            this.$route.name === "employee-documents-custom-document-edit"
          ) {
            dtatat = await this.replaceSenderSideVariablesInDraft(); 
          } else {
            dtatat = await this.replaceSenderSideVariables();
          }
          this.previewEditorContent = dtatat[dtatat.length - 1];
          this.loading = true;
          this.loadingText = "Generating Pdf Document...";
          let fields = this.editorFields;
          const ckEditorContent = this.editorContent;
          const element = document.createElement("div");
          element.innerHTML = ckEditorContent;
          const htmlContent = element.innerHTML;

          let companyDataId =
            this.getActiveWorkspace && this.getActiveWorkspace.company_id
              ? this.getActiveWorkspace.company_id
              : this.getActiveWorkspace.company_id._id;

          const { data } = await axios.post(`/ckeditorToPdfUpload`, {
            companyId: companyDataId,
            pdfFile: htmlContent,
            name: this.documentName,
          });
          this.loading = false;
          this.loading = true;
          this.loadingText = "Document Completion Is In Progress...";

          let conId, docTempId;
          if (
            this.$route &&
            this.$route.name === "employee-documents-custom-document-edit"
          ) {
            conId = this.getSingleDocumentData?.data?.configurable_document_id;
            docTempId = this.getSingleDocumentData?.data?.company_document_id;
          } else {
            conId = this.$route.params.configurable_document_id;
            docTempId = this.$route.params.document_id;
          }
          this.editorFields = fields;
          let documentData = {};
          let params = await this.prepareFieldsForCkEditor();
          let documentUsers = params.document_users;
          params.document_data = documentData;
          params.configurabledocumentid = conId;
          params.document_template_id = docTempId;
          params.document_upload_url = data.path;
          params.document_upload_urls = [data.path];
          params.document_download_s3_urls = data.downloadUrl;
          params.is_ck_editor_sender_side_finish = true;
          params.company_id = companyDataId;
          params.is_template = true;
          params.document_users = documentUsers;

          await this.$store.dispatch(
            "configurableDocuments/updateConfigurableDocument",
            params
          );
          let usersInCk = this.documentRecipientsList || this.documentUsers;
          console.log(usersInCk, this.editorFields);
          await this.updateCurrentUserEntityDataForCkEditorFields(
            usersInCk,
            this.editorFields,
            true
          );
          this.loading = false;
          await this.$store.dispatch("documents/finishSenderDocument", params);
          this.previewEditorContentModal = false;
          this.loading = false;
          this.documentDataLoading = false;
          if (this.getSenderFinishDocumentStatus) {
            this.$notify.success({
              title: "Success",
              message: "Document Completed Successfully",
            });

            let id = this.getSenderFinishDocumentData.employeeDocument._id;
            this.$router.push({
              name: "employee-documents-custom-document",
              params: {
                employee_document_id: id,
              },
            });
          }
          //}
        }
      } catch (err) {
        if (err.response && err.response.status === 413) {
          this.$notify.error({
            title: "Error",
            message: `${
              err.response.statusText
                ? err.response.statusText
                : "Payload Too Large"
            }`,
          });
        } else {
          console.log(err.response);
        }
        this.loading = false;
      }
    },
    async openAiAssistant() {
      try {
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: this.$route.params.configurable_document_id,
            include_download_url: true,
          }
        );
        this.documentDataLoading = true;
        this.loadingText = "Please Wait Redirecting To AI Interface...";
        let response = await axios.post("https://ai.esigns.io/kodefast_chat/", {
          admin_id: this.getConfigurableDocumentDataById._id,
          fileUrls: [
            this.getConfigurableDocumentDataById.document_download_s3_urls[0],
          ],
        });
        let data = response?.data;

        localStorage.setItem(
          "selectedAttachmentFile",
          this.getConfigurableDocumentDataById.document_download_s3_urls[0]
        );
        localStorage.setItem(
          "routeUrl",
          JSON.stringify({
            path: this.$route.path,
            params: {
              document_id: this.$route.params.document_id,
              configurable_document_id:
                this.$route.params.configurable_document_id,
            },
            query: this.$route.query,
          })
        );
        localStorage.setItem("aiData", JSON.stringify(data));
        this.$router.push({
          name: "AiAssistant",
          params: {
            selectedAttachmentFile:
              this.getConfigurableDocumentDataById.document_download_s3_urls[0],
            routeUrl: {
              path: this.$route.path,
              params: {
                document_id: this.$route.params.document_id,
                configurable_document_id:
                  this.$route.params.configurable_document_id,
              },
              query: this.$route.query,
            },
            aiData: data,
          },
        });
        this.documentDataLoading = false;
      } catch (err) {
        console.log(err);
        this.$message.error(err);
      }
    },
    mappingPrimaryFieldsData(matchingField) {
      const templateKeyParts = matchingField.template_key.split("@");
      const fieldType = templateKeyParts[1];
      const docUsers = this.documentRecipientsList || this.documentUsers || [];

      for (let i = 0; i < docUsers.length; i++) {
        let user = docUsers[i];
        if (
          user.type === "SENDER" &&
          matchingField.label.split("@")[0] === user.value
        ) {
          switch (fieldType) {
            case "first_name":
              matchingField.value = this.getAuthenticatedUser.first_name;
              break;
            case "last_name":
              matchingField.value = this.getAuthenticatedUser.last_name;
              break;
            case "address":
              matchingField.value = this.getAuthenticatedUser.address;
              break;
            case "phone":
              matchingField.value = this.getAuthenticatedUser.phone;
              break;
            case "company":
              matchingField.value = user.company_name;
              break;
            case "email":
              matchingField.value = this.getAuthenticatedUser.email;
              break;
            case "full_name":
              matchingField.value =
                this.getAuthenticatedUser.first_name +
                " " +
                this.getAuthenticatedUser.last_name;
              break;
            case "title":
              matchingField.value = this.getAuthenticatedUser.title;
              break;
          }
        } else if (
          user.type !== "SENDER" &&
          matchingField.label.split("@")[0] === user.value &&
          matchingField.contact_type ===
            (user.contact_type?._id || user.contact_type) &&
          user.email !== ""
        ) {
          switch (fieldType) {
            case "first_name":
              matchingField.value = user.first_name;
              break;
            case "last_name":
              matchingField.value = user.last_name;
              break;
            case "address":
              matchingField.value = user.address;
              break;
            case "phone":
              matchingField.value = user.phone;
              break;
            case "company":
              matchingField.value = user.company_name;
              break;
            case "email":
              matchingField.value = user.email;
              break;
            case "full_name":
              matchingField.value = user.first_name + " " + user.last_name;
              break;
            case "title":
              matchingField.value = user.title;
              break;
          }
        }
        // Check if the matchingField value has been set, if so, return
        if (matchingField.value) {
          return `<span style="color: #000000;">${matchingField.value}</span>`;
        }
      }

      console.log("No value matched for field:", matchingField.template_key);
      return `<span style="color: #FF0000;">[MISSING]</span>`;
    },
    prepareFieldsForCkEditor() {
      let signUsers = this.editorFields
        .filter((element) => element.type == "SIGNATURE")
        .flatMap((element) => element.filled_by);
      let conId;
      let docTempId;
      if (
        this.$route &&
        this.$route.name === "employee-documents-custom-document-edit"
      ) {
        (conId =
          this.getSingleDocumentData &&
          this.getSingleDocumentData.data &&
          this.getSingleDocumentData.data.configurable_document_id),
          (docTempId =
            this.getSingleDocumentData &&
            this.getSingleDocumentData.data &&
            this.getSingleDocumentData.data.company_document_id);
      } else {
        (conId = this.$route.params.configurable_document_id),
          (docTempId = this.$route.params.document_id);
      }
      let requiredUsers = this.editorFields.flatMap((el) => el.filled_by);
      let docUsers = this.documentRecipientsList || this.documentUsers;
      let users = docUsers.map((user, index) => {
        user.e_signature_order = index;
        if (signUsers.indexOf(user.value) != -1) {
          user.e_signature_required = true;
        } else {
          user.is_cc = true;
          user.e_signature_required = false;
        }
        if (user.contact_type && user.contact_type._id) {
          user.contact_type = user.contact_type._id;
        }
        return user;
      });
      users.map((user) => {
        if (requiredUsers.indexOf(user.value) != -1) {
          user.fields_required = true;
        } else {
          user.fields_required = false;
        }
      });
      this.is_published = this.is_publishing ? this.is_published : false;
      // this.mapDocumentFields();
      let inOrderFields = this.openCk
        ? this.editorFields
        : this.getFieldsFromElements();
      inOrderFields.sort((a, b) => {
        return a.y - b.y;
      });
      let timezone = this.getTimeZone();
      const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
      const fieldLabels = matches
        ? matches.map((label) => this.normalizeLabel(label.slice(2, -2)))
        : [];
      const labelCountMap = {};
      fieldLabels.forEach((label) => {
        if (labelCountMap[label]) {
          labelCountMap[label] += 1;
        } else {
          labelCountMap[label] = 1;
        }
      });

      // Normalize labels in editorFields and initialize filteredEditorFields
      const filteredEditorFields = [];
      this.editorFields.forEach((field) => {
        const normalizedFieldLabel = this.normalizeLabel(field.label);
        if (labelCountMap[normalizedFieldLabel] > 0) {
          for (let i = 0; i < labelCountMap[normalizedFieldLabel]; i++) {
            filteredEditorFields.push(field);
          }
          labelCountMap[normalizedFieldLabel] = 0; // Reset the count to 0 after processing
        }
      });
      this.editorFields = filteredEditorFields;
      let data = {
        id: conId || undefined,
        name: this.documentName,
        type: "CUSTOM",
        configure_type: "DYNAMIC",
        company_document_id: docTempId,
        background_images: this.backgroundImages,
        document_users: users,
        is_anyone_can_approve: this.isAnyoneCanApprove,
        is_template: this.isTemplate,
        template_title: this.documentName,
        is_published: this.is_published,
        lock_template_fields: this.lockTemplateFields,
        access_to_all: this.access_to_all,
        secure_access: this.secure_access,
        update_contacts: this.update_contacts,
        sender_time_zone: timezone,
        ck_editor_content: this.editorContent,
        ckeditor_fields: this.editorFields,
        pages: [
          {
            page_no: 1,
            background: this.hasBackground,
            background_image: this.backgroundImage,
            background_images: this.backgroundImages,
            style:
              "position: relative;width: 1240px;border: 1px solid #ccc;margin: 1em;max-height: 90vh;overflow: scroll;padding: 20px;",
            fields: this.fillingFields,
            ckeditor_unmapped_fields: this.ckFields,
            page_base_width: this.currentFormBuilderWidth,
            templates_used: Array.from(new Set(this.templatesUsed)),
            entities_used: Array.from(new Set(this.entitiesUsed)),
            checkbox_group: this.checkBoxGroupArray,
          },
        ],
      };
      if (
        this.getConfigurableDocumentDataById?.description &&
        this.getConfigurableDocumentDataById?.description?.length
      ) {
        data.description = this.getConfigurableDocumentDataById?.description;
      }
      return data;
    },
    async mapCkEditorData() {
      try {
        if (this.searchedLabel && this.searchedLabel.length) {
          this.searchedLabel = "";
          this.editorFields = [...this.searchedMapLables];
        }
        if (this.getSignerUserTypeLength <= 1) {
          let signLength = this.editorFields.filter(
            (e) =>
              ((e.isStatic && e.isStatic === "true") || e.isStatic === true) &&
              (e.input_type === "SIGNATURE" || e.type === "SIGNATURE")
          );
          if (this.editorFields && this.editorFields.length) {
            this.editorFields.forEach((e) => {
              if (
                e &&
                e.input_type &&
                e.input_type === "CURRENCY" &&
                e.value === ""
              ) {
                e.value = 0;
              }
              if (
                e &&
                (e.isStatic === "true" || e.isStatic === true) &&
                ((e && e.input_type === "SIGNATURE") || e.type === "SIGNATURE")
              ) {
                e.value = this.getAuthenticatedUser.signature;
              }
              if (
                signLength &&
                signLength.length &&
                (e.input_type == "DATE_SIGNED" || e.type == "DATE_SIGNED") &&
                e.filled_by === "SENDER"
              ) {
                let date = new Date();
                e.value = date;
              }
            });
          }
        }
        this.reloadEditorContent();
        this.loading = true;
        this.loadingText = "Mapping fields data into editor...";
        if (this.getSignerUserTypeLength > 1) {
          this.fillingEditorFields = false;
          this.changeFilledByModal = true;
        }
        let dtatat;
        let dtatat1;
        if (
          (this.$route &&
            this.$route.name &&
            this.$route.name === "edit-configure-template-image") ||
          this.$route.name === "edit-configure-document-image"
        ) {
          dtatat = await this.replaceSenderSideVariables();
        } else if (
          this.$route &&
          this.$route.name &&
          this.$route.name === "edit-configure-template-image"
            ? this.documentRecipientsList &&
              this.documentRecipientsList.length > 1 &&
              !this.checkSignerUsers.length
            : this.documentUsers &&
              this.documentUsers.length > 1 &&
              !this.checkSignerUsers.length
        ) {
          dtatat1 = await this.replaceVariables();
          this.editorContent = dtatat1[dtatat1.length - 1];
          if (this.getSignerUserTypeLength <= 1) {
            this.previewEditorContentModal = true;
          } else {
            this.fillingEditorFields = false;
          }
        } else if (
          (this.$route &&
            this.$route.name &&
            this.$route.name === "employee-documents-custom-document") ||
          this.$route.name === "employee-documents-custom-document-pp"
        ) {
          this.loading = true;
          this.loadingText = "Loading Editor Preview...";
          dtatat = await this.replaceVariablesInUserSide();
        } else {
          dtatat = await this.replaceSenderSideVariablesInDraft();
        }
        this.previewEditorContent = dtatat[dtatat.length - 1];
        this.senderFields = [];
        this.hasCkEditorEmptyFields = false;
        this.previewEditorContentModal = true;
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getAllPdfFieldsForSingleDocument(file) {
      let i = 0;
      const self = this;
      // if (file.raw.type != "application/pdf") {
      //   this.currentDoc++;
      //   return
      // }
      let url = URL.createObjectURL(file.raw);
      await pdfjsLib.getDocument(url).promise.then(setFieldsData);
      async function setFieldsData(pdfDoc) {
        for (let j = 1; j <= pdfDoc.numPages; j++) {
          await pdfDoc
            .getPage(j)
            .then(getPageInfo)
            .then(
              setTimeout(() => {
                if (j == pdfDoc.numPages) {
                  self.currentDoc++;
                }
              })
            );
        }
        return pdfDoc;
      }
      async function getPageInfo(page) {
        await page.getAnnotations().then((e) => {
          let obj = {};
          obj[self.pageCount] = {
            fields: e,
            docId: i,
            pageId: self.pageCount,
          };
          self.pdfFormFields[i] = {
            ...self.pdfFormFields[i],
            ...obj,
          };
          self.pageCount++;
        });
      }
    },
    async getAllPdfFields(file, id) {
      if (file.raw.type != "application/pdf") {
        this.currentDoc++;
        return;
      }
      let i = id;
      const self = this;
      let url = URL.createObjectURL(file.raw);
      await pdfjsLib.getDocument(url).promise.then(setFieldsData);
      async function setFieldsData(pdfDoc) {
        for (let j = 1; j <= pdfDoc.numPages; j++) {
          await pdfDoc
            .getPage(j)
            .then(getPageInfo)
            .then(
              setTimeout(() => {
                if (j == pdfDoc.numPages) {
                  self.currentDoc++;
                }
              })
            );
        }
        return pdfDoc;
      }
      async function getPageInfo(page) {
        await page.getAnnotations().then((e) => {
          let obj = {};
          obj[page.pageNumber] = {
            fields: e,
            docId: i,
            pageId: page.pageNumber,
          };
          self.pdfFormFields[i] = {
            ...self.pdfFormFields[i],
            ...obj,
          };
        });
      }
    },
    //Get contact types from contact
    getContactTypesFromContact(contact) {
      let result = [];
      if (contact?.contact_types && contact.contact_types.length) {
        result = contact.contact_types.map((e) => {
          if (e?.contact_type?._id) {
            e.contact_type = e.contact_type._id;
          }
          if (e?.account_data_id?._id) {
            e.account_data_id = e.account_data_id._id;
          }
          return e;
        });
      } else if (contact.contact_type) {
        let obj = {};
        obj["contact_type"] = contact.contact_type._id
          ? contact.contact_type._id
          : contact.contact_type;
        obj["has_login"] = true;
        if (contact.account_data_id) {
          obj["account_data_id"] = contact.account_data_id;
        }
        result = [obj];
      }
      if (
        this.getStandardContactType?._id &&
        (!result.length ||
          result.findIndex(
            (e) => e.contact_type == this.getStandardContactType?._id
          ) == -1)
      ) {
        result.push({
          contact_type: this.getStandardContactType?._id,
          has_login: true,
        });
      }
      return result;
    },
    getAccountDataId(contactTypes, contact_type) {
      if (contactTypes.length) {
        return contactTypes.find((e) => e.contact_type == contact_type);
      }
      return [];
    },
    mapCoordinates(elements, document_url_paths) {
      let results = [];
      for (let i = 0; i < document_url_paths.length; i++) {
        let documentDiv = document.getElementById(`wrapper_${i}`);
        if (documentDiv) {
          let docElements = elements.filter((e) => {
            if (
              e &&
              e.y > documentDiv.offsetTop &&
              e.y < documentDiv.offsetHeight + documentDiv.offsetTop
            ) {
              return e;
            }
          });
          let allFieldsPages = JSON.parse(JSON.stringify(docElements));
          let preDocHeight = 0;
          for (let k = 0; k < i; k++) {
            let maxPreLength = document.getElementById(`wrapper_${k}`)
              .childNodes.length;
            for (let j = 0; j < maxPreLength; j++) {
              preDocHeight =
                preDocHeight +
                document.getElementById(`wrapper_${k}`).childNodes[j].height;
            }
          }
          let maxLength = document.getElementById(`wrapper_${i}`).childNodes
            .length;
          let pageBreaks = [];
          let h = 0;
          for (let o = 0; o < maxLength; o++) {
            if (o == 0) {
              h =
                h +
                preDocHeight +
                document.getElementById(`wrapper_${i}`).childNodes[o].height;
            } else {
              h =
                h +
                document.getElementById(`wrapper_${i}`).childNodes[o].height;
            }
            pageBreaks.push(h);
          }
          pageBreaks.forEach((page, index) => {
            allFieldsPages = allFieldsPages.map((element) => {
              element.docId = i;
              if (
                element.y <= page + index * 6 &&
                (index == 0 ||
                  (index > 0 &&
                    element.y >= pageBreaks[index - 1] + (index - 1) * 6))
              ) {
                element.page = index + 1;
              } else if (element.y >= pageBreaks[pageBreaks.length - 1]) {
                element.page = pageBreaks.length;
              }
              return element;
            });
          });
          allFieldsPages = allFieldsPages.map((element) => {
            let heightOffset = document.getElementById(
              `${i}_canvas_${element.page}`
            ).offsetTop;
            if (heightOffset) {
              element.print_y = element.y - heightOffset;
            } else {
              element.print_y = element.y;
            }
            let offset = document.getElementById(
              `${i}_canvas_${element.page}`
            ).offsetLeft;
            if (offset) {
              element.print_x = element.x - offset;
            } else {
              element.print_x = element.x;
            }
            return element;
          });
          results = [...results, ...allFieldsPages];
        }
      }
      return results;
    },
    isDocExisted(doc, type) {
      type = type.toLowerCase();
      if (type == "standard") {
        return this.newlySelectedDocuments[type].find(
          (e) => e.type == doc.type && e.value == doc.value
        ) || this.documentsData.find((e) => e.standard_document == doc.value)
          ? true
          : false;
      } else {
        return this.newlySelectedDocuments[type].find(
          (e) => e._id == doc._id
        ) || this.documentsData.find((e) => e.company_document_id == doc._id)
          ? true
          : false;
      }
    },

    async onAddNewDocument() {
      this.configureCompanyDocumentsVisible = true;
      this.newlySelectedDocuments.static = [];
      this.newlySelectedDocuments.standard = [];
      this.newlySelectedDocuments.custom = [];
      this.newlySelectedDocuments.requested = [];
      this.searchDocumentFormData.type = "";
      this.searchDocumentFormData.group_id = "";
      this.searchDocumentFormData.category_id = "";
      this.searchDocumentFormData.searchedDocuments = [];
      this.fetchCategories();
      this.fetchGroups();
    },
    async fetchCategories() {
      await this.$store.dispatch("documentCategories/fetchAllCategories", {
        get_all: true,
      });
    },
    async fetchGroups() {
      await this.$store.dispatch("documentGroups/fetchAllGroups", {
        get_all: true,
      });
    },

    async getCompanyDocuments() {
      this.fetchingCompanyDocumentDetails = true;
      let params = {
        type: this.searchDocumentFormData.type || undefined,
        category: this.searchDocumentFormData.category_id || undefined,
        search_string: this.searchDocumentFormData.search_string || undefined,
        groups: this.searchDocumentFormData.group_id || undefined,
        get_all: true,
      };
      await this.$store.dispatch(
        "companyDocuments/fetchCompanyAllDocuments",
        params
      );

      this.fetchingCompanyDocumentDetails = false;
    },
    // addDocumentToStep(doc, type) {
    //   if (!this.isDocExisted(doc, type)) {
    //     this.newlySelectedDocuments[type].push(doc);
    //   }
    // },

    addDocumentToStep(doc, type) {
      if (!this.isDocExisted(doc, type)) {
        if (doc.type == "CUSTOM") {
          if (doc.configurable_document_id) {
            this.newlySelectedDocuments[type].push(doc);
          } else {
            this.$message.warning({
              message: "Selected Custom Document has no configuration",
              showClose: true,
            });
          }
        } else {
          this.newlySelectedDocuments[type].push(doc);
        }
      }
    },
    async doneCompanyDocuemnts() {
      this.configureCompanyDocumentsVisible = false;
      if (this.getTotalDocuments(this.newlySelectedDocuments) > 0) {
        await this.addWorkflowEmployeeDocument();
      }
    },
    async fecthEntityData() {
      try {
        if (this.selectedEntity && this.selectedEntity.length) {
          this.allEntitytemplateData = [];
          let params = {
            templateIds: this.selectedEntity
              .filter((e) => e.template_id)
              .flatMap((e) => e.template_id),
            entityId: this.$route.params.entity_id,
          };
          await this.$store.dispatch(
            "templatesData/fetchTemplateDataByEntity",
            params
          );
          if (this.getTemplateDataByentityId) {
            this.allEntitytemplateData = this.getTemplateDataByentityId;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    doneCompanyUserDocuemnts() {
      this.configureCompanyDocumentsVisible = false;
    },
    removeComapnyDocument(type, index) {
      this.newlySelectedDocuments[type].splice(index, 1);
    },
    preareNewlyAddedDocumentData() {
      let data = {
        static: [],
        standard: [],
        custom: [],
        requested: [],
      };
      data.static = this.newlySelectedDocuments.static.map((e) => e._id);
      data.custom = this.newlySelectedDocuments.custom.map((e) => e._id);
      data.requested = this.newlySelectedDocuments.requested.map((e) => e._id);
      data.standard = this.newlySelectedDocuments.standard.map((e) => e.value);
      return data;
    },
    getStandardDocumentSlug(standardDocument) {
      return companyDocumentsData.STANDARD_DOCUMENTS_MAP[standardDocument].slug;
    },
    closeUploadedDocumentDialog() {
      this.uploadRequestedDocumentDialog = false;
    },

    getEmployeeDocumentComponentByCompanyDocument(companyDocument) {
      let type = companyDocument.type;
      if (type == "CUSTOM") {
        return this.documentViewComponentsMap[type][
          companyDocument.configure_type
        ];
      } else {
        return this.documentViewComponentsMap[type];
      }
    },
    checkIsRepeatable(selectedItem) {
      if (
        selectedItem &&
        selectedItem.entity_id &&
        this.allUsersEntityData.length &&
        selectedItem.template_id
      ) {
        let selectedEntity = this.allUsersEntityData.find(
          (e) => e && e.id == selectedItem.entity_id
        );
        if (selectedEntity && selectedEntity.templates) {
          let selectedTemp = selectedEntity.templates.find(
            (e) => e && e.template_id == selectedItem.template_id
          );
          if (
            selectedTemp &&
            selectedTemp.is_repeatable &&
            !selectedItem.indexKey &&
            selectedItem.type != "REPEATABLE_PARENT" &&
            !selectedItem.parent_repeatable_field_key
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    checkDisabled() {
      if (this.selectedItem.type == "PRIMARY_FIELDS") {
        if (
          !this.selectedItem.selected_tag ||
          !this.selectedItem.selected_user
        ) {
          return true;
        }
        return false;
      }

      if (
        this.selectedItem.field_type == "ENTITY_FIELD" &&
        this.selectedItem.type !== "PARAGRAPH"
      ) {
        if (
          !this.selectedItem.template_id ||
          !this.selectedItem.key ||
          this.checkIsRepeatable(this.selectedItem)
        ) {
          return true;
        }
      }
      if (this.selectedItem.type == "CURRENCY") {
        if (
          this.selectedItem.validations &&
          !this.selectedItem.validations.currency
        ) {
          return true;
        }
      }
      if (this.selectedItem.field_type == "ENTITY") {
        console.log("0-0-0-0");
        if (!this.selectedItem.entity_id || !this.selectedItem.key) {
          return true;
        }
      }
      if (this.selectedItem.field_type == "ENTITY_VARIABLE") {
        if (
          !this.selectedItem.entity_id ||
          !this.selectedItem.key ||
          (!this.selectedItem.template_id &&
            !this.selectedItem.key.includes("~")) ||
          (!this.selectedItem.parent_entity_field_key &&
            !this.selectedItem.parent_repeatable_field_key)
        ) {
          return true;
        }
      }
      if (this.selectedItem.type == "INITIAL" && this.selectedItem.text) {
        return false;
      }
      if (this.selectedItem.type == "IMAGE") {
        return false;
      }
      // if(this.selectedItem.type == "CHECKBOX" && !)
      if (
        this.selectedItem.type == "TABLE" &&
        this.selectedItem.data &&
        this.selectedItem.headers &&
        this.selectedItem.data.length &&
        this.selectedItem.headers.length
      ) {
        return false;
      }
      if (
        this.selectedItem.type == "my-signature" &&
        !this.selectedItem.source
      ) {
        return true;
      } else if (this.selectedItem.type == "DATE_SIGNED") {
        return !this.selectedItem.signature_field;
      }

      if (
        this.selectedItem.type == "HEADING" &&
        this.selectedItem.selected_tag &&
        this.selectedItem.content &&
        this.selectedItem.content.trim()
      ) {
        return false;
      }

      if (
        (this.selectedItem.type == "PARAGRAPH" ||
          this.selectedItem.type == "SINGLE_LINE_CONTENT") &&
        this.selectedItem.content &&
        this.selectedItem.content.trim()
      ) {
        return false;
      }
      if (this.selectedItem.type == "HORIZONTAL_LINE") {
        return false;
      }

      if (this.selectedItem.type === "FORMULA") {
        return (
          !this.validExpression(this.selectedItem) || !this.selectedItem.label
        );
      }
      return this.selectedItem.label && this.selectedItem.filled_by
        ? false
        : true;
    },
    validExpression(field) {
      let exp = "";
      let lastField = "";
      let err = false;
      if (
        field &&
        field.selected_fields &&
        field.selected_fields[0] &&
        field.selected_fields[0].input_type === "DATE_TIME_RANGE"
      ) {
        return true;
      } else {
        let selectedFieldsLength = field.selected_fields.length;
        if (selectedFieldsLength && selectedFieldsLength > 2) {
          field.selected_fields.map((el) => {
            if (el.type == "OPERATOR") {
              exp += el.operator + " ";
              if (lastField != "operator") {
                lastField = "operator";
              } else {
                err = true;
              }
            } else if (el.type == "PARENTHESES") {
              exp += el.parentheses + " ";
              lastField = "";
            } else {
              exp += Math.floor(Math.random() * Math.floor(9)) + " ";
              if (lastField != "number") {
                lastField = "number";
              } else {
                err = true;
              }
            }
            return;
          });
          try {
            if (err) {
              return false;
            } else {
              mexp.eval(exp);
              return true;
            }
          } catch (e) {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    async save(isRepeatable = false) {
      if (this.getAllFormTemplatesData?.length === 0) {
        await this.fetchAllFormTemplates();
      }
      let checkUsersFromDraft = this.documentRecipientsList
        ? this.documentRecipientsList
        : this.documentUsers;
      let ccUsers = checkUsersFromDraft.find(
        (user) => user.type != "SENDER" && user.user_type === "CC"
      );
      let entityId =
        this.selectedItem.type == "ENTITY"
          ? this.selectedItem.entity_id
          : this.selectedUserEntity && this.selectedUserEntity.id
          ? this.selectedUserEntity.id
          : "";
      let exKey = this.selectedRepeatableIndex;
      let fieldsKey = this.recipientSelection;
      let pageBoundary = this.getPagesBoundaries(this.selectedItem.y);
      var newWidth = 0;

      if (this.selectedItem.type == "ENTITY") {
        bus.$emit("entity-selected");
      }
      if (this.selectedItem.type == "MY_INITIAL") {
        await this.$refs.configureField.saveCapturedInitial();
        await this.getCurrentUserInitial();
        this.elements.forEach((el) => {
          if (el.type === "MY_INITIAL") {
            el.value = this.currentUserInitial;
          }
        });
        this.selectedItem.value = this.currentUserInitial;
      }
      if (this.selectedItem.type == "CHECKBOX") {
        this.selectedItem.width = this.selectedItem.height;
        this.isAspectRatio = false;
        setTimeout(() => {
          this.isAspectRatio = true;
        }, 1000);
      }
      if (this.selectedItem.type === "my-signature") {
        await this.$refs.configureField.saveCapturedSignature();
        if (this.getAuthenticatedUser && this.getAuthenticatedUser.signature) {
          this.elements.forEach((el) => {
            if (el.type === "my-signature") {
              el.source = this.getAuthenticatedUser.signature;
            }
          });
          this.selectedItem.source = this.getAuthenticatedUser.signature;
          var dimensions = await this.getImageDimensions(
            this.selectedItem.source
          );
          this.isAspectRatio = false;
          this.elements.forEach((el) => {
            if (el.type === "my-signature") {
              el.source = this.getAuthenticatedUser.signature;
              // el.width = dimensions.w;
              el.height = (el.width * dimensions.h) / dimensions.w;
            }
          });
          this.selectedItem.height =
            (this.selectedItem.width * dimensions.h) / dimensions.w;
          setTimeout(() => {
            this.isAspectRatio = true;
          }, 1000);
        }
      }

      if (this.selectedItem.type === "PRIMARY_FIELDS") {
        if (
          this.selectedItem.selected_tag == "address" &&
          this.selectedItem.addressInfo == "Personal Address" &&
          this.getAuthenticatedUser &&
          this.getAuthenticatedUser.address !== ""
        ) {
          if (
            this.selectedItem.content &&
            this.selectedItem.content != this.getAuthenticatedUser.address
          ) {
            this.$set(this.selectedItem, "content", this.selectedItem.content);
          } else {
            this.$set(
              this.selectedItem,
              "content",
              this.getAuthenticatedUser && this.getAuthenticatedUser.address
            );
          }
        }
        if (
          this.selectedItem.selected_tag == "address" &&
          this.selectedItem.addressInfo == "Company Address" &&
          this.getCompanyDetails &&
          this.getCompanyDetails.address !== ""
        ) {
          if (
            this.selectedItem.content &&
            this.selectedItem.content != this.getCompanyDetails.address
          ) {
            this.$set(this.selectedItem, "content", this.selectedItem.content);
          } else {
            this.$set(
              this.selectedItem,
              "content",
              this.getCompanyDetails && this.getCompanyDetails.address
            );
          }
        }
        if (
          this.selectedItem.selected_tag === "address" &&
          ((this.selectedItem.addressInfo === "Company Address" &&
            this.getCompanyDetails.address &&
            this.getCompanyDetails.address === "") ||
            (this.selectedItem.addressInfo === "Personal Address" &&
              this.getAuthenticatedUser.address &&
              this.getAuthenticatedUser.address === "")) &&
          this.selectedItem.content
        ) {
          this.$set(this.selectedItem, "content", this.selectedItem.content);
        }
        if (
          (this.selectedItem.selected_tag == "full_name" ||
            this.selectedItem.selected_tag == "last_name" ||
            this.selectedItem.selected_tag == "first_name") &&
          this.selectedItem.is_use_boxes
        ) {
          if (this.selectedItem.boxNumber === 0) {
            this.selectedItem.boxNumber =
              this.selectedItem.content && this.selectedItem.content.length
                ? this.selectedItem.content.length
                : 0;
          } else {
            this.selectedItem.boxNumber = this.selectedItem.boxNumber
              ? this.selectedItem.boxNumber
              : 0;
          }
          if (!this.selectedItem.boxWidth) {
            this.$message.error("Please add box width!");
            return;
          }
          if (
            this.selectedItem.boxNumber > 0 &&
            this.selectedItem.boxes &&
            this.selectedItem.boxes.length == 0
          ) {
            if (this.selectedItem.content && this.selectedItem.content.length) {
              this.selectedItem.boxes = Array.from(this.selectedItem.content);
            }
          }
        }
        this.selectedItem.content = this.getFieldVal(
          this.selectedItem.selected_tag,
          this.selectedItem.selected_user,
          this.selectedItem.selected_tag == "address" &&
            this.selectedItem.addressInfo === "Company Address"
            ? true
            : false
        );
        let fontSize =
          this.selectedItem.font && this.selectedItem.font.size
            ? this.selectedItem.font.size
            : 16;
        this.selectedItem.height =
          this.selectedItem.selected_tag == "address"
            ? this.selectedItem.height
            : fontSize * 1.5;
        if(this.selectedItem.type == "HTML_CONTENT"){
          this.$set(this.selectedItem, "content", this.selectedItem.content);
          if(this.selectedItem.filled_by === ""){
            this.$set(this.selectedItem, "filled_by", this.recipientSelection);
          }
        }
        // if (!this.selectedItem.content) {
        //   this.selectedItem.content = "";
        // }
        if (this.selectedItem.template_id) {
          delete this.selectedItem.template_id;
        }
        if (this.selectedItem.value) {
          delete this.selectedItem.value;
        }
        this.selectedItem.field_type = "CUSTOM_FIELD";
        this.elements[this.selectedItemIndex] = { ...this.selectedItem };
        this.addItemDataDialogVisible = false;

        return;
      } else if (
        this.selectedItem.type != "HEADING" &&
        this.selectedItem.type != "PARAGRAPH" &&
        this.selectedItem.type != "SINGLE_LINE_CONTENT" &&
        this.selectedItem.type != "HTML_CONTENT" 
      ) {
        if (this.selectedItem.content) {
          delete this.selectedItem.content;
        }
      }
      if (
        this.selectedItem.type === "SINGLE_LINE_TEXT" &&
        !this.selectedItem.is_use_boxes
      ) {
        if (this.selectedItem.selected_tag) {
          delete this.selectedItem.selected_tag;
        }
        let fontSize =
          this.selectedItem.font && this.selectedItem.font.size
            ? this.selectedItem.font.size
            : 16;
        this.selectedItem.height = fontSize * 1.5;
        if (this.selectedItem.content) {
          delete this.selectedItem.content;
        }
        // let indx = this.getLastIndexOfElement("SINGLE_LINE_TEXT");
        // this.selectedItem.label = "Single Line Text " + indx + 1;
        if (
          this.selectedItem.field_type != "ENTITY_FIELD" &&
          this.selectedItem.field_type != "ENTITY" &&
          this.selectedItem.field_type != "ENTITY_VARIABLE"
        ) {
          this.selectedItem.field_type = "CUSTOM_FIELD";
        }
        this.elements[this.selectedItemIndex] = { ...this.selectedItem };
        this.addItemDataDialogVisible = false;
        return;
      }
      if (this.selectedItem.type == "TABLE") {
        let tblWidth = this.selectedItem.headers.length * 70;
        if (this.selectedItem.width < tblWidth) {
          this.selectedItem.width = tblWidth;
        }
        this.selectedItem.font = this.selectedItem.rowFont;
      }
      if (this.selectedItem.type == "CHECKBOX_GROUP") {
        let selectedTemp = this.getAllFormTemplatesData.data.find(
          (el) => el._id == this.selectedItem.template_id
        );
        let currentField = this.selectedItem;
        let currentFormTempField = selectedTemp?.sections[0]?.fields.find(
          (el) => {
            let key = "";
            if (this.selectedItem.global_variable_entity_field) {
              key = this.selectedItem.global_variable_entity_field.split("#");
            } else {
              key = this.selectedItem.key.split("#");
            }
            let key1 = key.length > 1 ? key[1] : "";
            if (el.input_type === this.selectedItem.type && el.key === key1) {
              return el;
            }
          }
        );

        fieldsKey = fieldsKey + "-" + currentField.key;
        let templateValue = this.setEntityDataToField(
          fieldsKey,
          currentField.template_id,
          isRepeatable ? this.selectedRepeatableIndex : null,
          currentField.type,
          currentField.allow_multiple
        );

        let font = currentField.font;
        let indx = this.getLastIndexOfElement(currentField.type);
        this.checkBoxGroupArray.push({
          key: isRepeatable
            ? currentFormTempField?.key
              ? currentFormTempField.key + "/" + exKey
              : this.selectedItem.key.split("#")[1]
            : currentFormTempField?.key
            ? currentFormTempField.key
            : this.selectedItem.key.split("#")[1],
          name: currentFormTempField.label,
          filled_by: this.recipientSelection,
          min_value: currentFormTempField.min_selection
            ? currentFormTempField.min_selection
            : 0,
          max_value: currentFormTempField.max_selection
            ? currentFormTempField.max_selection
            : 0,
          required: currentField.validations.required,
          show_checkbox_label: true,
          isFromFormTemplate: true,
        });
        currentField.width = 30;
        currentField.height = 30;
        let obj = {
          key: isRepeatable
            ? currentFormTempField.key
              ? currentFormTempField.key + "/" + exKey
              : this.selectedItem.key.split("#")[1]
            : currentFormTempField.key
            ? currentFormTempField.key
            : this.selectedItem.key.split("#")[1],
          name: currentFormTempField.label,
          filled_by: this.recipientSelection,
          min_value: currentFormTempField.min_selection
            ? currentFormTempField.min_selection
            : 0,
          max_value: currentFormTempField.max_selection
            ? currentFormTempField.max_selection
            : 0,
          required: currentField.validations.required,
          show_checkbox_label: true,
          isFromFormTemplate: true,
        };
        this.checkBoxGrpObj = obj;
        let checkbosgroupoptions =
          currentFormTempField.options && currentFormTempField.options.length
            ? currentFormTempField.options.length
            : currentFormTempField.selected_enity_field_data &&
              currentFormTempField.selected_enity_field_data.options &&
              currentFormTempField.selected_enity_field_data.options.length;
        for (let i = 0; i < checkbosgroupoptions; i++) {
          let data = {
            type: "CHECKBOX_GROUP",
            is_checkbox_group: true,
            section: "input",
            style: "background:#cbe2d8;",
            addNewField:
              this.selectedRepeatableIndex == "newData" ? true : false,
            height: currentField.height,
            width: currentField.width,
            value:
              templateValue &&
              templateValue.indexOf(currentField.options[i]) !== -1
                ? true
                : false,
            template_field_id: currentField._id,
            template_id: currentField.template_id,
            indexKey: isRepeatable ? exKey : null,
            entity_id: entityId,
            options: currentField.options || [],
            x: currentField.x + (i * currentField.width + 4),
            y: currentField.y,
            validations: currentField.validations,
            font:
              currentField.styles && currentField.styles.font
                ? currentField.styles.font
                : font,
            index: indx,
            key: fieldsKey + "/" + currentField.options[i],
            label: currentField.options[i],
            placeholder: currentField.placeholder || currentField.label,
            required:
              currentField.validations && currentField.validations.required
                ? currentField.validations.required
                : false,
            allow_to_add_new_entity_data: currentField.allow_quick_add,
            group_key: isRepeatable
              ? currentFormTempField.key + "/" + exKey
              : currentFormTempField.key,
            field_type: "ENTITY_FIELD",
            filled_by:
              ccUsers && checkUsersFromDraft && checkUsersFromDraft.length <= 2
                ? "SENDER"
                : this.recipientSelection || "SENDER_OR_RECEIVER",
            selected_user: this.recipientSelection || "SENDER_OR_RECEIVER",
            parent_entity_field_key: currentField.parent_entity_field_key,
            parent_template_field: currentField.global_variable_entity_field,
          };
          let addFieldToHistory = {
            data: data,
            action: "add",
            index: this.elements.length,
          };
          this.history.push(addFieldToHistory);
          this.elements.push(data);
        }
        this.deleteElement();
      }
      if (this.selectedItem.type == "HEADING") {
        newWidth = 0;
        if (
          this.selectedItem.field_type === "ENTITY_VARIABLE" &&
          !this.selectedItem.content
        ) {
          this.selectedItem.content = this.selectedItem.label;
          this.selectedItem.selected_tag = "h1";
        }
        if (this.selectedItem.selected_tag == "h1") {
          this.selectedItem.font.size = 32;
          newWidth = this.selectedItem.content.length * 14;
          this.selectedItem.height = this.selectedItem.height + 10;
        } else if (this.selectedItem.selected_tag == "h2") {
          this.selectedItem.font.size = 24;
          newWidth = this.selectedItem.content.length * 13;
        } else if (this.selectedItem.selected_tag == "h3") {
          this.selectedItem.font.size = 18.72;
          newWidth = this.selectedItem.content.length * 13;
        } else if (this.selectedItem.selected_tag == "h4") {
          this.selectedItem.font.size = 16;
          newWidth = this.selectedItem.content.length * 13;
        } else if (this.selectedItem.selected_tag == "h5") {
          this.selectedItem.font.size = 13.28;
          newWidth = this.selectedItem.content.length * 13;
        } else if (this.selectedItem.selected_tag == "h6") {
          this.selectedItem.font.size = 10.72;
          newWidth = this.selectedItem.content.length * 13;
        }
        if (newWidth > this.selectedItem.width) {
          if (newWidth > this.pageWidth) {
            newWidth = this.pageWidth - 10;
          }
          if (
            this.selectedItem.x + newWidth >
            pageBoundary.pageOffset + pageBoundary.pageWidth
          ) {
            this.selectedItem.x =
              pageBoundary.pageOffset + pageBoundary.pageWidth - newWidth;
          }
          this.selectedItem.width = newWidth;
        }
      }
      if (this.selectedItem.type == "IMAGE") {
        if (this.imgData) {
          this.selectedItem.value = this.imgData;
          if (this.imgDataWidth > 0 && this.imgDataHeight > 0) {
            if (this.imgDataWidth > this.pageWidth) {
              this.selectedItem.width = this.pageWidth - 5;
              this.selectedItem.height =
                this.imgDataHeight * (this.pageWidth / this.imgDataWidth);
              this.selectedItem.x = 0;
              this.selectedItem.y = 0;
            } else {
              if (
                this.pageWidth <
                this.selectedItem.x + this.imgDataWidth - 5
              ) {
                this.selectedItem.x =
                  this.selectedItem.x -
                  (this.selectedItem.x + this.imgDataWidth - this.pageWidth) -
                  5;
              }
              this.selectedItem.width = this.imgDataWidth;
              this.selectedItem.height = this.imgDataHeight;
            }
          }
        }
      }

      if (this.selectedItem.type == "ATTACHMENT") {
        if (this.selectedItem.label.length > 12) {
          this.selectedItem.width = this.selectedItem.label.length * 8;
        }
        // let indx = this.getLastIndexOfElement("ATTACHMENT");
        // this.selectedItem.label = "Attachment " + indx + 1;
        if (this.selectedItem.field_type != "ENTITY_FIELD") {
          this.selectedItem.field_type = "CUSTOM_FIELD";
        }
      }
      if (this.selectedItem.type == "CHECKBOX" && this.selectedItem.group_key) {
        let gIndex = -1;
        gIndex = this.checkBoxGroupArray.findIndex(
          (g) => g.key == this.selectedItem.group_key
        );
        console.log(
          "this.checkBoxGrpObj this.checkBoxGrpObj ",
          this.checkBoxGrpObj
        );
        //this.current_group_index = gIndex;
        this.checkBoxGroupArray[gIndex] = this.checkBoxGrpObj;
      }
      if (
        this.selectedItem.type == "NUMBER" &&
        !this.selectedItem.is_use_boxes
      ) {
        let fontSize =
          this.selectedItem.font && this.selectedItem.font.size
            ? this.selectedItem.font.size
            : 16;
        this.selectedItem.height = fontSize * 1.5;
      }
      if (
        this.selectedItem.field_type != "ENTITY_FIELD" &&
        this.selectedItem.is_use_boxes &&
        (this.selectedItem.type == "SINGLE_LINE_TEXT" ||
          this.selectedItem.type == "NUMBER")
      ) {
        console.log("hihih", this.selectedItem.boxNumber);

        this.selectedItem.boxNumber = this.selectedItem.boxNumber
          ? this.selectedItem.boxNumber
          : this.selectedItem.value && this.selectedItem.value.length;
        if (!this.selectedItem.boxWidth) {
          this.$message.error("Please add box width!");
          return;
        }
        if (
          this.selectedItem.boxNumber * this.selectedItem.boxWidth >
          this.selectedItem.width
        ) {
          this.$message.error(
            "Boxes length should not be greater than the field length"
          );
        }
        if (
          this.selectedItem.boxNumber > 0 &&
          this.selectedItem.boxes.length == 0
        ) {
          console.log("hihih2");
          this.selectedItem.boxes = Array.from(this.selectedItem.value);
        }
      }
      if (
        this.selectedItem.field_type == "ENTITY_FIELD" &&
        this.selectedItem.is_use_boxes &&
        (this.selectedItem.type == "SINGLE_LINE_TEXT" ||
          this.selectedItem.type == "NUMBER")
      ) {
        console.log("hihih", this.selectedItem.value);
        console.log("hihih1", this.selectedItem.value.toString().length);
        let valueWithoutCommas;
        if (this.selectedItem.value.toString().includes(",")) {
          valueWithoutCommas = this.selectedItem.value
            .toString()
            .replace(/,/g, "");
        } else {
          valueWithoutCommas = this.selectedItem.value;
        }
        this.selectedItem.boxNumber = this.selectedItem.boxNumber
          ? this.selectedItem.boxNumber
          : valueWithoutCommas.length;
        if (!this.selectedItem.boxWidth) {
          this.$message.error("Please add box width!");
          return;
        }
        console.log(this.selectedItem.boxNumber, valueWithoutCommas);
        if (
          this.selectedItem.boxNumber > 0 &&
          this.selectedItem.boxes &&
          this.selectedItem.boxes.length == 0
        ) {
          console.log("hihih2");
          this.selectedItem.boxes = Array.from(valueWithoutCommas);
        }
      }
      if (
        (this.selectedItem.type === "DATE" ||
          (this.selectedItem.type === "DATE" &&
            this.selectedItem.field_type == "ENTITY_VARIABLE")) &&
        this.selectedItem.is_use_boxes
      ) {
        console.log(this.selectedItem.value);
        if (!this.selectedItem.boxWidth) {
          this.$message.error("Please add box width!");
          return;
        }
        let formattedDate = "";
        if (
          this.selectedItem.value &&
          this.selectedItem.value.toString() &&
          this.selectedItem.value.toString().length
        ) {
          const date = new Date(this.selectedItem.value);
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const year = String(date.getFullYear());
          formattedDate = `${month}${day}${year}`;
        }
        this.selectedItem.boxNumber = this.selectedItem.boxNumber
          ? this.selectedItem.boxNumber
          : formattedDate.length;
        if (formattedDate && formattedDate.length) {
          console.log("1", formattedDate.length, formattedDate);
          this.selectedItem.boxNumber = this.selectedItem.boxNumber
            ? this.selectedItem.boxNumber
            : formattedDate.length
            ? formattedDate.length
            : 0;
        }
        if (
          this.selectedItem.boxNumber > 0 &&
          this.selectedItem.boxes &&
          this.selectedItem.boxes.length === 0
        ) {
          this.selectedItem.boxes = Array.from(formattedDate);
          // this.selectedItem.boxNumber = this.selectedItem.boxNumber ? this.selectedItem.boxNumber : formattedDate.length ? formattedDate.length : 0 ;
        }
      }
      if (
        this.selectedItem.type == "SELECT" ||
        this.selectedItem.type == "MULTI_SELECT"
      ) {
        let fontSize =
          this.selectedItem.font && this.selectedItem.font.size
            ? this.selectedItem.font.size
            : 16;
        this.selectedItem.height =
          this.selectedItem.selected_tag == "address"
            ? this.selectedItem.height
            : fontSize * 1.5;
        if (
          (this.selectedItem &&
            this.selectedItem.options &&
            !this.selectedItem.options.length) ||
          this.selectedItem.options.some((opt) => opt.trim() === "")
        ) {
          this.$message({
            message: "Please add options and those should not only spaces",
            type: "warning",
          });
          return;
        }
        if (
          this.selectedItem &&
          this.selectedItem.type === "MULTI_SELECT" &&
          this.selectedItem.max_selection &&
          this.selectedItem.min_selection &&
          this.selectedItem.min_selection > this.selectedItem.max_selection
        ) {
          this.$message.error(
            "Min Selection should not be greater than Max Selection"
          );
          return;
        }
        if (
          this.selectedItem &&
          this.selectedItem.type === "MULTI_SELECT" &&
          this.selectedItem.max_selection &&
          this.selectedItem.min_selection &&
          this.selectedItem.options.length &&
          this.selectedItem.max_selection > this.selectedItem.options.length
        ) {
          this.$message.error(
            `There are ${this.selectedItem.options.length} Options. Max Selection should not be greater than ${this.selectedItem.options.length}.`
          );
          return;
        }
        if (this.selectedItem && this.selectedItem.type === "MULTI_SELECT") {
          if (this.selectedItem.min_selection === 0) {
            this.selectedItem.required = false;
          } else {
            this.selectedItem.required = true;
          }
        }
      }
      if (
        this.selectedItem.type == "LIST" &&
        this.selectedItem.field_type !== "ENTITY_FIELD"
      ) {
        if (
          this.selectedItem &&
          this.selectedItem.list_data &&
          !this.selectedItem.list_data.length
        ) {
          this.$message({
            message: "Please add list items data",
            type: "warning",
          });
          return;
        }
      }
      if (this.selectedItem.type == "SINGLE_LINE_CONTENT") {
        if (this.selectedItem && this.selectedItem.content === "") {
          this.$message({ message: "Please add content...!", type: "warning" });
          return;
        }
      }
      if (this.selectedItem.type == "DATA_TABLE") {
        if (
          this.selectedItem &&
          !this.selectedItem.map_keys &&
          this.selectedItem.headers &&
          !this.selectedItem.headers.length
        ) {
          this.$message({
            message: "Please add table cloumns data",
            type: "warning",
          });
          return;
        }
        this.selectedItem.font = this.selectedItem.rowFont;
        // let tblWidth = this.selectedItem.headers.length * 70;
        // if (this.selectedItem.width < tblWidth) {
        //   this.selectedItem.width = tblWidth;
        // }
      }
      if (this.selectedItem.type == "SIGNATURE") {
        this.selectedItem.width = 225;
        this.selectedItem.height = 36;
        this.isAspectRatio = false;
        setTimeout(() => {
          this.isAspectRatio = true;
        }, 1000);
      }
      if (this.selectedItem.type == "INITIAL") {
        this.selectedItem.width = 100;
        this.selectedItem.height = 50;
      }
      if (
        this.selectedItem.type == "TIME" &&
        this.selectedItem.field_type == "ENTITY_VARIABLE"
      ) {
        let selectedTemp = this.getAllFormTemplatesData.data.find(
          (el) => el._id == this.selectedItem.template_id
        );
        let currentTimeFields = selectedTemp?.sections[0]?.fields.filter(
          (el) => {
            return this.selectedItem.type === el.inputType && el.is_fixed;
          }
        );
        if (currentTimeFields) {
          currentTimeFields.forEach((e) => {
            this.selectedItem.is_fixed = e.is_fixed;
            this.selectedItem.time_range = e.properties.time_range;
            this.selectedItem.step_time = e.properties.step_time;
          });
        } else {
          this.selectedItem.is_fixed = false;
        }
      }
      this.imgData = null;
      this.elements[this.selectedItemIndex] = {
        ...JSON.parse(JSON.stringify(this.selectedItem)),
      };
      if (this.selectedItem.type == "FORMULA") {
        bus.$emit("formula-updated");
      }
      if (this.selectedItem.type == "DATE") {
        // this.selectedItem.placeholder =
      }
      this.addItemDataDialogVisible = false;
      this.dialogFormVisible = false;

      if (this.selectedItem.type == "PARAGRAPH") {
        // setTimeout(() => {

        // }, 100);
        const element = document.getElementById(
          `MULTI_LINE_TEXT_${this.selectedItem.index}`
        );
        if (element && element.scrollHeight) {
          this.selectedItem.height = element.scrollHeight;
          this.elements[this.selectedItemIndex] = {
            ...JSON.parse(JSON.stringify(this.selectedItem)),
          };
        }
      }
      this.selectedItem.key = "";
      this.selectedItem.label = "";
      this.selectedItem.filled_by = "";
      this.selectedItem.placeholder = "";
    },
    checkLabelIsReserved() {
      let reserve = false;
      if (
        this.selectedItem.label &&
        this.reservedLabels.indexOf(this.selectedItem.label.toLowerCase()) != -1
      ) {
        reserve = true;
      }
      return reserve;
    },
    async updatingProfileInfo() {
      let updatedCompanyData = {};
      let updatedProfileData = {
        first_name: this.getAuthenticatedUser.first_name,
        last_name: this.getAuthenticatedUser.last_name,
        title: this.getAuthenticatedUser.title,
        phone: this.getAuthenticatedUser.phone,
        address: this.getAuthenticatedUser.address,
      };
      if (this.elements && this.elements.length) {
        this.elements.forEach((e) => {
          if (
            e &&
            e.type === "PRIMARY_FIELDS" &&
            e.selected_user === "SENDER"
          ) {
            if (
              e.selected_tag === "address" &&
              e.addressInfo === "Company Address" &&
              e.content
            ) {
              updatedCompanyData = {
                ...this.getCompanyDetails,
                address: e.content,
              };
            } else if (
              (e.selected_tag === "address" &&
                e.addressInfo === "Personal Address") ||
              e.selected_tag === "first_name" ||
              e.selected_tag === "last_name" ||
              e.selected_tag === "title" ||
              e.selected_tag === "phone"
            ) {
              console.log("updatedProfileData", updatedProfileData);

              updatedProfileData = {
                ...updatedProfileData,
                [e.selected_tag]:
                  e.content || this.getAuthenticatedUser?.[e.selected_tag],
              };
            }
          }
        });
        if (Object.keys(updatedCompanyData).length > 0) {
          await this.$store.dispatch(
            "company/updateCompany",
            updatedCompanyData
          );
        }

        if (Object.keys(updatedProfileData).length > 0) {
          await this.$store.dispatch(
            "auth/updateUserProfile",
            updatedProfileData
          );
        }
      } else {
        if (this.editorFields && this.editorFields.length) {
          this.editorFields.forEach((e) => {
            if (e && e.input_type.startsWith("PRIMARY_FIELDS")) {
              const templateKeyParts = e.template_key.split("@");
              const fieldType = templateKeyParts[1];

              switch (fieldType) {
                case "first_name":
                case "last_name":
                case "title":
                case "phone":
                case "address":
                  updatedProfileData = {
                    ...updatedProfileData,
                    [fieldType]:
                      e.value || this.getAuthenticatedUser?.[fieldType],
                  };

                  break;
              }
            }
          });

          // if (Object.keys(updatedCompanyData).length > 0) {
          //   await this.$store.dispatch(
          //     "company/updateCompany",
          //     updatedCompanyData
          //   );
          // }

          if (Object.keys(updatedProfileData).length > 0) {
            await this.$store.dispatch(
              "auth/updateUserProfile",
              updatedProfileData
            );
          }
        }
      }
    },
    onRowDelete(row) {
      console.log(this.selectedItem, this.activatedItem);
      console.log("--=-=", this.filesData, row, this.selectedItem);
      let index = this.filesData[this.activatedItem.key].files.findIndex(
        (obj) => obj.uid === row.uid
      );
      if (index > -1) {
        this.filesData[this.activatedItem.key].files.splice(index, 1);
        this.activatedItem.files = this.filesData[this.activatedItem.key].files;
      }
      console.log(this.selectedItem, this.activatedItem);
    },
    closeUploadDocuments() {
      this.uploadDocuments = false;
    },
    searchUploadDocumentName() {
      this.documentUploadData = [];
      this.documentUploadData = this.filesData.filter(
        (e) =>
          e.name
            .toLowerCase()
            .includes(this.searchUploadDocument.toLowerCase()) == true
      );
      //  }
    },
    headerCellStyle() {
      return {
        backgroundColor: "#F2F6FC",
        color: "#606266",
        fontWeight: "bold",
      };
    },
    getFieldValuesForEntityDataFields(array, key) {
      const [templateId, field] = key.split("#");
      const foundItem = array.find((item) => item.template_id === templateId);
      if (
        foundItem &&
        foundItem.template_data &&
        foundItem.template_data[field]
      ) {
        return foundItem.template_data[field];
      } else {
        return null; // or any default value you want to return if the key is not found
      }
    },
    async generateEntityLablesIntoValues(users) {
      await this.fetchEntities();
      let d1 = [];
      let usersss = this.documentRecipientsList
        ? this.documentRecipientsList
        : users;
      usersss.forEach(async (user) => {
        console.log(user);
        this.contactTypeEntityId =
          user && user.value === "SENDER"
            ? user.entity_data_id?.entity_id
            : user.entity_data_id?.entity_id
            ? user.entity_data_id?.entity_id
            : this.contactTypeEntityId;
        this.allMentionableFields = await this.fetchEntityDetails(
          this.contactTypeEntityId,
          true
        );
        if (user.email && user.entity_data_id?.entity_id) {
          //await this.getAllNecessaryDetails();
          let seletedUserTemplateData = [];
          if (this.getTemplatesData && this.getTemplatesData.length) {
            seletedUserTemplateData = this.getTemplatesData.filter(
              (e) =>
                (user.entity_data_id &&
                  user.entity_data_id._id &&
                  e.entity_data_id == user.entity_data_id._id) ||
                e.entity_data_id == user.entity_data_id
            );
          }
          let da = [];
          if (this.allMentionableFields && this.allMentionableFields.length) {
            da = seletedUserTemplateData.filter((e) =>
              this.allMentionableFields.some(
                (el) => el.template_id === e.template_id
              )
            );
          }
          d1 = da;
          //});
          this.allSelectedEntityMentionFields = this.allMentionableFields;
          if (this.allMentionableFields) {
            this.allMentionableFields = this.allMentionableFields.map(
              (item) => ({
                label: item.label,
                template_key: item.template_key,
                id: item.template_key,
                input_type: item.inputType,
                value: this.findValueByKey(d1, item.template_key, item),
                width: item.width,
              })
            );
            this.allMentionableFields.forEach(async (field) => {
              let labelPattern = `\\[\\[${field.label}\\]\\]`;
              let regex = new RegExp(labelPattern, "g");
              if (
                this.editorContent.includes(`[[${field.label}]]`) &&
                field.value
              ) {
                await this.addNullOrUndeifnedValuesFromMentions(
                  field,
                  field.value
                );
                this.editorContent = this.editorContent.replace(
                  regex,
                  field.value
                );
              } else if (
                field.input_type === "DATE" ||
                field.input_type === "DATE_TIME_RANGE" ||
                field.input_type === "TIME_RANGE" ||
                field.input_type === "DATE_RANGE" ||
                field.input_type === "TIME" ||
                field.input_type === "DATE_TIME"
              ) {
                const formattedValue = this.formatField(field);
                await this.addNullOrUndeifnedValuesFromMentions(
                  field,
                  formattedValue
                );
                this.editorContent = this.editorContent.replace(
                  regex,
                  formattedValue
                );
                //return `<span style="color: #000000;">${formattedValue}</span>`;
              } else {
                if (
                  (this.editorContent.includes(`[[${field.label}]]`) &&
                    field.value === null) ||
                  field.value === undefined ||
                  field.value === "null" ||
                  field.value === "undefined"
                ) {
                  await this.addNullOrUndeifnedValuesFromMentions(field);
                  console.log("000-000", field);
                  return `<input type="text" style="width: ${field.width}px; border-top: none; border-right: none; border-left: none; border-bottom: 2px solid yellow;" readonly>`;
                }
              }
            });

            console.log(this.editorContent);
          }
        }
        //console.log("--this.allMentionableFields---", this.allMentionableFields);
      });
    },
    getFieldsFromEditorElements() {
      let duplicateKeyCounter = {},
        highCounter = {};
      this.templatesUsed = [];
      this.entitiesUsed = [];
      this.allStaticFieldsEditor.forEach((e) => {
        if (duplicateKeyCounter[e.type]) {
          duplicateKeyCounter[e.type] = duplicateKeyCounter[e.type] + 1;
        } else {
          duplicateKeyCounter[e.type] = 1;
        }
        if (e.key && e.key.includes(e.type.toLowerCase())) {
          let keyCount = e.key.split(e.type.toLowerCase() + "_")[1];
          if (Number(keyCount)) {
            if (Number(keyCount) > highCounter[e.type]) {
              highCounter[e.type] = Number(keyCount);
            } else {
              highCounter[e.type] = Number(keyCount);
            }
          }
        }
      });
      return this.allStaticFieldsEditor.map((e) => {
        if (!e.key) {
          e.key =
            e.type.toLowerCase() +
            "_" +
            (highCounter[e.type]
              ? highCounter[e.type] + 1
              : duplicateKeyCounter[e.type]
              ? duplicateKeyCounter[e.type]
              : 1);
        }
        return e;
      });
    },
    getFieldsFromElements() {
      let duplicateKeyCounter = {},
        highCounter = {};
      this.templatesUsed = [];
      this.entitiesUsed = [];
      this.elements.forEach((e) => {
        if (duplicateKeyCounter[e.type]) {
          duplicateKeyCounter[e.type] = duplicateKeyCounter[e.type] + 1;
        } else {
          duplicateKeyCounter[e.type] = 1;
        }
        if (e.key && e.key.includes(e.type.toLowerCase())) {
          let keyCount = e.key.split(e.type.toLowerCase() + "_")[1];
          if (Number(keyCount)) {
            if (Number(keyCount) > highCounter[e.type]) {
              highCounter[e.type] = Number(keyCount);
            } else {
              highCounter[e.type] = Number(keyCount);
            }
          }
        }
      });
      return this.elements.map((e) => {
        if (!e.key) {
          e.key =
            e.filled_by +
            "@" +
            e.type.toLowerCase() +
            "_" +
            (highCounter[e.type]
              ? highCounter[e.type] + 1
              : duplicateKeyCounter[e.type]
              ? duplicateKeyCounter[e.type]
              : 1);
        }
        return e;
      });
      // this.elements.forEach((e) => {
      //   let key
      //   if(e.key){
      //     key = e.key;
      //   }else{
      //     key = this.generateKeyForField(e.label);
      //   }
      //   if (!e.entity_id && !e.template_id) {
      //     if (typeof duplicateKeyCounter[key] == "number") {
      //       let index =
      //         e.key && e.key.includes(key + "_") && e.key.split(key + "_")[1]
      //           ? parseInt(e.key.split(key + "_")[1]) + 1
      //           : 1;
      //       if (index > duplicateKeyCounter[key]) {
      //         duplicateKeyCounter[key] = index;
      //       }
      //     } else {
      //       duplicateKeyCounter[key] =
      //         e.key && e.key.includes(key + "_") && e.key.split(key + "_")[1]
      //           ? parseInt(e.key.split(key + "_")[1]) + 1
      //           : 1;
      //     }
      //   }
      // });
      // console.log("duplicateKeyCounter duplicateKeyCounter", duplicateKeyCounter)
      // return this.elements.map((element, index) => {
      //   element.sno = index + 1;
      //   if (!element.entity_id && !element.template_id) {
      //     let newKey
      //     if(element.key){
      //       newKey = element.key;
      //     }else{
      //       newKey = this.generateKeyForField(element.label);
      //     }
      //     if (duplicateKeyCounter[newKey]) {
      //       if (!element.key) {
      //         let index = parseInt(duplicateKeyCounter[newKey]);
      //         element.key = newKey + "_" + index + 1;
      //         duplicateKeyCounter[newKey]++;
      //       }
      //     } else {
      //       duplicateKeyCounter[newKey] = 1;
      //       if (!element.key) {
      //         element.key = newKey + '_' + index + 1;
      //         duplicateKeyCounter[newKey]++;
      //       }
      //     }
      //   }
      //   if (element.type == "HEADING") {
      //     element.content = element.content ? element.content : element.value;
      //     element.source = element.selected_tag;
      //   }

      //   if (element.type == "PARAGRAPH") {
      //     element.content = element.content ? element.content : element.value;
      //     element.source = "";
      //   }
      //   if (element.type == "HORIZONTAL_LINE") {
      //     element.value = element.content;
      //   }
      //   return element;
      // });
    },
    checkAndRemoveDateSignedFields() {
      const signatureFields = this.editorFields.filter(
        (e) =>
          e.input_type === "SIGNATURE" &&
          e.filled_by === this.recipientSelection
      );
      const hasSignature = signatureFields && signatureFields.length > 0;
      let content = this.editorContent;
      if (!hasSignature) {
        this.editorFields = this.editorFields.filter((field) => {
          return !(
            field.input_type === "DATE_SIGNED" &&
            field.filled_by === this.recipientSelection
          );
        });
      }
      const labelRegex = /\[\[(.*?)\]\]/g;
      let match;
      const labels = [];

      while ((match = labelRegex.exec(content)) !== null) {
        labels.push(match[0]);
      }
      const existingFieldLabels = this.editorFields.map(
        (field) => `[[${field.input_type} ${field.name}]]`
      );
      const updatedContent = labels.reduce((updatedContent, label) => {
        if (!existingFieldLabels.includes(label)) {
          updatedContent = updatedContent.replace(label, "");
        }
        return updatedContent;
      }, content);

      this.editorContent = updatedContent;
      const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
      const labelsInContent = matches
        ? matches.map((label) => label.slice(2, -2))
        : [];
      const labelCountMap = labelsInContent.reduce((map, label) => {
        map[label] = (map[label] || 0) + 1;
        return map;
      }, {});

      const filteredEditorFields = [];
      this.editorFields.forEach((field) => {
        if (labelCountMap[field.label] > 0) {
          for (let i = 0; i < labelCountMap[field.label]; i++) {
            filteredEditorFields.push(field);
          }
          labelCountMap[field.label] = 0;
        }
      });
      this.editorFields = filteredEditorFields;
    },
    deleteElement() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data
          .lock_template_fields
      ) {
        //this.$message.warning('This document fields are locked');
      } else {
        if (this.selectedItemIndex > -1 && this.selectedItem) {
          if (
            this.elements[this.selectedItemIndex] &&
            this.elements[this.selectedItemIndex]["type"] &&
            (this.elements[this.selectedItemIndex]["type"] == "SIGNATURE" ||
              this.elements[this.selectedItemIndex]["type"] ==
                "my-signature") &&
            this.elements[this.selectedItemIndex]["filled_by"] == "SENDER"
          ) {
            let selectedUserSignatures = this.elements.filter(
              (el) =>
                (el.type == "SIGNATURE" || el.type == "my-signature") &&
                el.filled_by == "SENDER"
            );
            if (selectedUserSignatures && selectedUserSignatures.length == 1) {
              // let dateSignedIndex = this.elements.findIndex(el => el.type == 'DATE_SIGNED' && el.filled_by == 'SENDER');
              // this.elements.splice(dateSignedIndex, 1);
              let dateSignedIndexs = [];
              this.elements.forEach((el, index) => {
                if (el.type == "DATE_SIGNED" && el.filled_by == "SENDER") {
                  dateSignedIndexs.push(index);
                }
              });
              if (dateSignedIndexs.length) {
                dateSignedIndexs.forEach((el) => this.elements.splice(el, 1));
              }
            }
          }
          if (
            this.elements[this.selectedItemIndex] &&
            this.elements[this.selectedItemIndex]["type"] &&
            this.elements[this.selectedItemIndex]["filled_by"] &&
            this.elements[this.selectedItemIndex]["type"] == "SIGNATURE" &&
            this.elements[this.selectedItemIndex]["filled_by"] != "SENDER"
          ) {
            let selectedUserSignatures = this.elements.filter(
              (el) =>
                el.type == "SIGNATURE" &&
                el.filled_by ==
                  this.elements[this.selectedItemIndex]["filled_by"]
            );
            if (selectedUserSignatures && selectedUserSignatures.length == 1) {
              // let dateSignedIndex = this.elements.filter(el => el.type == 'DATE_SIGNED' && el.filled_by == this.elements[this.selectedItemIndex]['filled_by']);
              let dateSignedIndexs = [];
              this.elements.forEach((el, index) => {
                if (
                  el.type == "DATE_SIGNED" &&
                  el.filled_by ==
                    this.elements[this.selectedItemIndex]["filled_by"]
                ) {
                  dateSignedIndexs.push(index);
                }
              });
              if (dateSignedIndexs.length) {
                dateSignedIndexs.forEach((el) => this.elements.splice(el, 1));
              }
            }
          }
          if (
            this.elements[this.selectedItemIndex] &&
            this.elements[this.selectedItemIndex]["type"] == "ENTITY" &&
            this.checkForChildren
          ) {
            this.$message({
              message:
                "Some fields are associated to this entity field. Can't delete.",
              type: "warning",
            });
            return;
          }
          if (
            this.elements[this.selectedItemIndex] &&
            this.elements[this.selectedItemIndex]["type"] ==
              "REPEATABLE_PARENT" &&
            this.checkForRepeatableChildren
          ) {
            this.$message({
              message:
                "Some fields are associated to this repeatable field. Can't delete.",
              type: "warning",
            });
            return;
          }
          console.log(
            "567",
            this.selectedItem,
            this.elements[this.selectedItemIndex]
          );
          let addFieldToHistory = {
            action: "delete",
            data: this.selectedItem,
            index: this.selectedItemIndex,
          };
          this.history.push(addFieldToHistory);
          // console.log(this.elements);

          this.elements.splice(this.selectedItemIndex, 1);

          this.addItemDataDialogVisible = false;
          this.dialogFormVisible = false;
          this.selectedItemIndex = -1;
          this.selectedItem = {};
        }
      }
    },
    generateKeyForField(label) {
      let parsedLabel = label.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");
      return parsedLabel;
    },
    fillImageData({ file, imgDataHeight, imgDataWidth }) {
      this.imgData = file;
      this.imgDataWidth = imgDataWidth;
      this.imgDataHeight = imgDataHeight;
    },
    checkRoleDuplicate(name, index) {
      let checkExistedUser = "";
      this.users.forEach((e, i) => {
        if (
          e.name &&
          name &&
          (typeof index != "number" || (index > -1 && index != i))
        ) {
          if (
            this.generateKeyForField(name) == this.generateKeyForField(e.name)
          ) {
            checkExistedUser = e.name;
          }
        }
      });
      //
      if (checkExistedUser) {
        return false;
      } else {
        return true;
      }
    },
    async getCompanyInformation() {
      this.loading = true;
      try {
        // await this.$store.dispatch(
        //   "company/fetchCompany",
        //   this.getActiveWorkspace.company_id
        // );
        if (this.getCompanyDetails) {
          console.log("called successfully");
          this.loading = false;
          return this.getCompanyDetails;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
  },
};
